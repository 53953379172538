<template>
    <div @click="$emit('userSelected', this.recipientId, this.recipientDisplayname, this.recipientConnectedAccountStatus, recipientImageDataComputed, this.recipientDiffCurrency)" class="wrapper-todiv"  style="cursor:pointer;">
        <div class="userinfoframe" style="height: 9vh">
            <table align=left style="height:85%;" border=0 width=100%>
            <thead>
            <tr>
                <!-- ref font size 0 below, weird hack required see https://stackoverflow.com/questions/10500249/perfect-vertical-image-alignment -->
                <td align=left width=20% style="height: 8vh; font-size: 0; text-align: center"><img border=0 :v-if="recipientImageData!==undefined" :src="recipientImageDataComputed" style="height: 7vh; border-radius:50%"></td>
                 <!-- <td align=left width=20% ><img :v-if="recipientImageData!==undefined" :src="'data:image/png;base64,' + recipientImageData" width="50" height="50" ></td> -->
                <!-- '../assets/tulogoapp.png' -->
                <td >
                <!-- nested table -->
                <table border=0 width=100%>
                <thead>
                    <tr>
                        <td class="username" style="text-align: left; outline:0.0em solid green;">{{ this.recipientDisplayname }}</td> 
                    </tr>
                </thead>
                </table>
                </td>
                
            </tr>
            </thead>
            </table>
        </div>
        <p></p>
    </div>
</template>

<script>
export default {
    name: 'Recipient',
    props:  {
        recipientId: String,
        recipientDisplayname: String,
        recipientImageData: String,
        recipientImageFileType: String,
        recipientConnectedAccountStatus: Boolean,
        recipientDiffCurrency: Object,
    },
    computed: {
        recipientImageDataComputed() {

            // /console.log("Let's compute image");
            if (this.recipientImageData) {
                // // /console.log("YEA:: " + this.recipientImageData);
                return 'data:image/' + this.recipientImageFileType + ';base64,' + this.recipientImageData;
            } else {
                // // /console.log("NAY");
                return require('@/assets/tulogoapp.png');
                // this was the syntax for plain old img src, but since it is now bound :src, the above is the syntax  "../assets/tulogoapp.png";
            }
        } 
    },
    mounted(){

        // console.log("this.recipientDisplayname: " + this.recipientDisplayname);
        // console.log("RECIPIENT recipientDiffCurrency: " + JSON.stringify(this.recipientDiffCurrency, null, 2));
    },

}
</script>

<style scoped>


</style>