<template>

<div class="topcontent-div">
  <div></div>  <!-- everything is being handled as pop up - we could put static page content here -->
    <!-- showPopUpOk --><transition name="fade">
    <div v-show="showPopUpOk" class="modal-overlayfrombottomblur" style="z-index: 999;">
      <div class="modal" style="padding-top: 3vh; padding-bottom: 3vh; min-height: 40vh; width: 70%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=80%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
              {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr v-show="!this.suppressOk">
            <td style="height: 10vh">
              <div style="padding-top: 2vh"><button class="widebtn" ref="show-popup-ok" style="width: 50%; height: 6vh; " @click="this.doPopUpOk();">Ok</button></div>
            </td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
  <!-- showSignUpLogin --><transition name="screenslideup">
    <div v-show="showSignUpLogin" class="modal-overlay" style="outline:0.0em dashed black; ">
      <div class="modal" style="height: 65vh; outline:0.0em dashed red"> 
        
        <!-- put in table for dev speed need to learn more about flex -->

        <table border=0 width=90%> 
          
          <tr>
            <td>
                <div class="divl"><button class="text-only-button cancel" style="height: 6vh;" @click="this.doShowHomeScreen();">Cancel</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="wrapper-todiv">
                <div style="" >
                  <img class="tulogo"  style="height: 10vh;" alt="thankU logo" src="../assets/tutxtlogo.png">
                </div>
                <div class="primarytextmidcentre" style="font-size: 16px; height: 8vh;" >
                 <b>cashless tipping</b>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="height: 8vh;" ><button class="widebtn" style="width: 50%; height: 6vh; " ref="save-displayname" @click="this.doLogin();">Log in</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="height: 8vh;" ><button class="widebtn" style="width: 70%; height: 6vh; " ref="save-displayname" @click="this.doSignUp();">Create account</button></div>
            </td>
          </tr>
          <tr> <!-- spacer --> <td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
  <!-- showLogin --><transition name="screenslideup">
    <div v-show="showLogin" class="modal-overlay">
      <div class="modal" style="height: 65vh;"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=90%> 
          <tr>
            <td>
                <div class="divl" style="height: 6vh"><button class="text-only-button cancel" @click="doCancelShowLogin();">Cancel</button></div>
            </td>
          </tr>
         <tr>
            <td>
              <div class="wrapper-todiv" style="height: 10vh">
                <div style="" >
                  <img class="tulogo"  style="height: 7vh;" alt="thankU logo" src="../assets/tutxtlogo.png">
                </div>
                
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="height: 6vh; display: flex;">
                <input type="email" class="standard-textbox input" ref="email-login" placeholder="email">
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="height: 6vh; display: flex;">
                <input data-private="ipsum" type="textbox" class="standard-textbox input" ref="pw-login" placeholder="password" autocorrect="off" autocapitalize="none" autocomplete="off">
              </div>
            </td>
          </tr>
          <tr>
            <td style="height: 12vh">
              <div><button class="widebtn" style="width: 70%; height: 5vh;" @click="this.doLoginDB();">Log in</button></div>
            </td>
          </tr>
          <tr>
            <td>
                <div class="divc tb-padding"><button class="text-only-button padded" ref="forgotten-password" @click="this.showForgottenPassword=true">forgotten your password?</button></div>
            </td>
          </tr>
          <tr> <!-- spacer --> <td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
  <!-- showForgottenPassword --><transition name="screenslideup">
    <div v-show="showForgottenPassword" class="modal-overlayfrombottomblur">
      <div class="modal" style="height: 65vh;"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=90%> 
          <tr>
            <td>
                <div class="divl"><button class="text-only-button cancel" @click="this.showForgottenPassword=false;">Cancel</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              Reset password
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="height: 6vh; display: flex;">
                <input type="email" class="standard-textbox input" ref="email-for-reset" placeholder="email">
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div><button class="widebtn" style="width: 75%; height: 5vh; " @click="this.doResetPassword();">Reset Password</button></div>
            </td>
          </tr>
          <tr>
            <td style="display: flex;">
              <div class="lt-blue notes-text">
              If you have an email registered with thankU, you will receive a password reset email - just click the link it contains and enter a new password, then login here with your new password.
              </div><br>
            </td>
          </tr>
         
          <tr> <!-- spacer --> <td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
  <!-- showSignUp --><transition name="screenslideup">
    <div v-show="showSignUp" class="modal-overlay">
      <div class="modal" style="height: 80vh;"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=90%> 
          <tr>
            <td>
                <div class="divl" style="height: 6vh"><button class="text-only-button cancel" @click="doCancelShowSignUp();">Cancel</button></div>
            </td>
          </tr>
         <tr>
            <td>
              <div class="wrapper-todiv" style="height: 10vh">
                <div style="" >
                  <img class="tulogo"  style="height: 7vh;" alt="thankU logo" src="../assets/tutxtlogo.png">
                </div>
                
              </div>
            </td>
          </tr>
         
          <tr>
            <td>
              <div style="height: 6vh; display: flex;">
                <input type="textbox" class="standard-textbox input" ref="displayname-signup" placeholder="e.g. your first name">
              </div>
            </td>
          </tr>
          <tr>
            <td style="display: flex;">
              <div class="lt-blue notes-text" style="width: 60%">
              Enter e.g. your first name above - this is so other people will know it's you
              </div><br>
            </td>
          </tr>
          <tr>
            <td>
              <div style="height: 6vh; display: flex;">
                <input type="email" class="standard-textbox input" ref="email-signup" placeholder="email">
              </div>
            </td>
          </tr>
          <tr>
            <td style="display: flex;">
              <div class="lt-blue notes-text" style="width: 60%">
              Your email address is never shared with anyone
              </div><br>
            </td>
          </tr>
          <tr>
            <td>
              <div style="height: 6vh; display: flex;">
                <input data-private="ipsum" type="textbox" class="standard-textbox input" ref="pw-signup" placeholder="password" autocorrect="off" autocapitalize="none" autocomplete="off">
              </div>
            </td>
          </tr>
          <tr>
            <td style="height: 12vh">
              <div><button class="widebtn" style="width: 50%; height: 5vh;" @click="this.doSignUpDB();">Sign Up</button></div>
            </td>
          </tr>
          <tr> <!-- spacer --> <td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
  <!-- showGetConnectedIntro --><transition name="screenslideup">
    <div v-show="showGetConnectedIntro" class="modal-overlay">
      <div class="modal" style="padding-top: 4vh; padding-bottom: 3vh"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=90%> 
          <tr>
            <td>
                <div class="divl" style="height: 6vh"><button class="text-only-button cancel" @click="this.doCancelShowGetConnectedIntro();">Cancel</button></div>
            </td>
          </tr>
         <tr>
            <td>
              <div class="wrapper-todiv" style="height: 10vh">
                <div style="" >
                  <img class="tulogo"  style="height: 7vh;" alt="thankU logo" src="../assets/tutxtlogo.png">
                </div>
                
              </div>
            </td>
          </tr>
          <tr>
            <td align=center>
              <div class="lt-blue txtc" :style="{ 'height': this.titleHeight + 'vh' }">
                <span v-if="this.showTwoSteps === true"><b>2 quick steps to receive tips{{this.atVenueName}}:</b></span>
                <span v-if="this.showTwoSteps === false"><b>Last quick step to receive tips:</b></span>
              </div>
            </td>
          </tr>
          <tr>
            <td width=100%>
              <table width=100% border=0>
                <tr style="height: 8vh;">
                  <td width=10%></td>
                  <td width=90% align=left>
                    <div v-if="this.showTwoSteps === true" class="lt-blue txtc" style="font-size: 2.25vh">1. create an account<p></p>2. connect it to your bank account
                    </div>
                     <div v-if="this.showTwoSteps === false" class="lt-blue txtc" style="font-size: 2.25vh">As you are already logged in, you just need to connect to your bank account
                    </div>
                  </td>  
                </tr>
              </table>
            </td>
          </tr>
          
          <tr>
            <td style="display: flex;">
              <div class="lt-blue notes-text" style="width: 100%">
              <span v-if="this.showTwoSteps === true">These 2 steps take </span><span v-if="this.showTwoSteps === false">This step takes </span> less than a minute. Your bank details are stroed securely by thankU and our payment processor Stripe Inc. You will need your bank account details to hand{{ this.countrySpecificBankDetails }}.
              </div>
            </td>
          </tr>
          
          <tr>
            <td style="height: 10vh">
              <div><button class="widebtn" style="width: 50%; height: 5vh;" @click="doShowConnectToBankAccount();">Go!</button></div>
            </td>
          </tr>

          <tr v-if="this.showTwoSteps === false">
            <td >
              <div><button class="widebtn" style="width: 50%; height: 5vh;" @click="doCancelShowGetConnectedIntro(true);">Start over</button></div>
            </td>
          </tr>
          
        </table>       
      </div>
    </div>
  </transition>
  <!-- showConnectToBankAccount --><transition name="screenslideup">
    <div v-show="this.showConnectToBankAccount === true" class="modal-overlay">
      <div class="modal" style="padding-top: 4vh; padding-bottom: 3vh"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=90%> 
          <tr>
            <td>
                <div class="divl" style="height: 6vh"><button class="text-only-button cancel" @click="this.doCancelShowConnectToBankAccount()">Cancel</button></div>
            </td>
          </tr>
         <tr>
            <td>
              <div class="wrapper-todiv" style="height: 10vh">
                <div style="" >
                  <img class="tulogo"  style="height: 7vh;" alt="thankU logo" src="../assets/tutxtlogo.png">
                </div>
                
              </div>
            </td>
          </tr>
         
          <tr>
            <td width=100%>
              <div style="height: 6vh; display: flex;">
                <select style="height: 4.4vh; min-width: 75%;" class="standard-textbox input" name="country" ref="country" @change="doDetectSelectChange()">
                  <option value="UK">UK</option>
                  <!-- <option value="USA">USA</option> -->
                  
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td style="display: flex;">
              <div class="lt-blue notes-text" style="width: 80%">
              Select the country where your bank account is - email us at hello@thanku.app if yours isn't listed
              </div><br>
            </td>
          </tr>
           <tr>
            <td>
              <div style="height: 6vh; display: flex;">
                <input data-private="ipsum" type="textbox" class="standard-textbox input" style="min-width: 71%;" ref="accountName" placeholder="account name">
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="height: 6vh; display: flex;">
                <input data-private="ipsum" type="textbox" class="standard-textbox input" style="min-width: 71%;" ref="sortCode" :placeholder=sortCodeOrRoutingNumber>
              </div>
            </td>
          </tr>
         
          <tr>
            <td>
              <div style="height: 6vh; display: flex;">
                <input data-private="ipsum" type="textbox" class="standard-textbox input"  style="min-width: 71%;" ref="accountNumber" placeholder="account number">
              </div>
            </td>
          </tr>
          <tr>
            <td style="display: flex;">
              <div class="lt-blue notes-text" style="width: 80%">
              thankU does not store your bank details, only a secure digital token from our payment processor Stripe Inc., like Apple Pay and Google Pay
              </div><br>
            </td>
          </tr>
          <tr>
            <td style="height: 10vh">
              <div><button class="widebtn" style="width: 70%; height: 5vh;" @click="this.doConnectBankAccount();">Connect account</button></div>
            </td>
          </tr>
          <tr> <!-- spacer --> <td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
  <!-- showPlaceholderAccountPage --><transition name="screenslideup">
    <div v-show="showPlaceholderAccountPage" class="modal-overlay">
      <div class="modal" style="padding-top: 3vh; padding-bottom: 3vh"> 
        <!-- put in table for dev speed need to learn more about flex -->

        <table border=0 width=95%> 
          <tr>
            <td>
                <div class="divl" style="height: 6vh"><button class="text-only-button cancel" @click="this.doShowHomeScreen();">Home screen</button></div>
            </td>
          </tr>
         <tr>
            <td>
              <div class="wrapper-todiv" style="height: 10vh">
                <div style="" >
                  <img class="tulogo"  style="height: 7vh;" alt="thankU logo" src="../assets/tutxtlogo.png">
                </div>
                
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              Welcome {{ this.displayname }}!
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeReduced + '%', 'line-height': '17px', }">                 
              You are now logged in. This will be your account page, but we are sorry that it isn't quite ready yet. In the meantime, if you would like to access your account screen, may we please ask you to download the thankU app from the App Store or from Google Play depending on your phone and login with the same details. You will be able to access full account information on the Account screens in either of those platform apps. We thank you for your understanding, <p></p>best wishes, Adrian<br>thankU founder<p></p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div><button class="widebtn" style="width: 50%; height: 5vh; " @click="this.doLogout();">Log out</button></div>
            </td>
          </tr>
          <tr v-show="this.showOrderButton">
            <td>
              <p></p>
              <div><button class="widebtn" style="width: 50%; height: 5vh; " @click="this.doOrder();">O</button></div>
            </td>
          </tr>
          <tr v-show="this.showTipHistoryButton">
            <td>
              <p></p>
              <div><button class="widebtn" style="width: 50%; height: 5vh; " @click="this.doShowTipHistory();">TH</button></div>
            </td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>

  <RecipientDashboardScreen v-if="this.showRecipientDashboard" :userObjectId='this.userObjectId' @next-action="doNext"/>
  <TipHistoryScreen v-if="this.showTipHistory" :userData='this.userData' @next-action="doNext"/>
  <TipInfoScreenRecipient v-if="this.showTipInfoRecipient" :userData ='this.userData' @next-action="doNext"/>
  <TipFeesInfoScreenRecipient v-if="this.showTipFeesInfoRecipient" :userData='this.userData' @next-action="doNext"/>
  <TipInfoScreenTipper v-if="this.showTipInfoTipper" :userData ='this.userData' @next-action="doNext"/>
  <TipFeesInfoScreenTipper v-if="this.showTipFeesInfoTipper" :userData='this.userData' @next-action="doNext"/>
  <AccountScreen :key="componentKey" v-if="this.showAccountScreen===true" :userData='this.userData' @next-action="doNext"/>
  <PayoutsScreen v-if="this.showPayouts" :userData='this.userData' @next-action="doNext"/>
  <PayoutBreakdownScreen v-if="this.showPayoutBreakdown" :userData='this.userData' :payoutId='this.payoutId'  @next-action="doNext"/>
  <PayoutsManageScreen :key="componentKey" v-if="this.showPayoutsManage" :userData='this.userData' @next-action="doNext"/>
  <MakePayoutScreen :key="componentKey" v-if="this.showMakePayout" :userData='this.userData' @next-action="doNext"/>
  <ManagementDashboardScreen v-if="this.showManagementDashboard" :userData='this.userData' :payoutId='this.payoutId'  @next-action="doNext"/>
</div> 

</template>

<script>

const Parse = require('parse/node');

import {loadStripe} from '@stripe/stripe-js/pure';

import TipHistoryScreen from './TipHistory.vue';
import PayoutsScreen from './Payouts.vue';
import PayoutBreakdownScreen from './PayoutBreakdown.vue';
import PayoutsManageScreen from './PayoutsManage.vue';
import MakePayoutScreen from './MakePayout.vue';
import RecipientDashboardScreen from './RecipientDashboard.vue';
import AccountScreen from './Account.vue';
import TipInfoScreenRecipient from './TipInfoRecipient.vue';
import TipFeesInfoScreenRecipient from './TipFeesInfoRecipient.vue';
import TipInfoScreenTipper from './TipInfoTipper.vue';
import TipFeesInfoScreenTipper from './TipFeesInfoTipper.vue';
import Recipient from './Recipient.vue';
import ManagementDashboardScreen from './ManagementDashboard.vue';
import shared from '../shared.js';
import LogRocket from 'logrocket';

var stripe;


export default ({
  inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
  name: 'AccountPrep',
  data() {
    return {
      showSignUpLogin: false,
      showLogin: false,
      showSignUp: false,
      showPopUpOk: false,
      popUpMsgTitle: "",
      popUpMsgBody: "",
      showForgottenPassword: false,
      nowFinished: false,
      hidePasswordReset: false,
      showGetConnectedIntro: false,
      mustConnectToBankAccount: false,
      showConnectToBankAccount: false,
      sortCodeOrRoutingNumber: "",
      fontSizeTitle: 2.7, // vh
      fontSizeNormal: 2.4, // vh, not currently used
      fontSizeTitleLineHeight: 5, //vh
      fontSizePopUpMsg: 2.3, // vh
      fontSizePopUpMsgLineHeight: 3.4,// vh
      fontSizeReduced: 80, 
      stripePublishableKey: process.env.VUE_APP_STRIPE_ENV === "dev" ? process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_TEST : process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_LIVE,
      suppressOk: false,
      doBackupToken: false,
      showPlaceholderAccountPage: false,
      displayname: "",
      nextShowAccountScreen: false,
      showOrderButton: false,
      orderMode: "live",
      countrySpecificBankDetails: "",
      showTwoSteps: true,
      mustSavePWBackend: false,
      showTipHistory: false,
      showTipHistoryButton: false,
      showRecipientDashboard: false,
      showAccountScreen: false,
      showTipInfoRecipient: false,
      showTipFeesInfoRecipient: false,
      showTipInfoTipper: false,
      showTipFeesInfoTipper: false,
      paymentObjectId: undefined,
      payment: undefined,
      showPayouts: false,
      userObjectId: "",
      showPayoutBreakdown: false,
      showPayoutsManage: false,
      showMakePayout: false,
      payoutId: "",
      userData: shared.userDataStruct(),
      override: false,
      componentKey: false,
      showManagementDashboard: false,
      terminator: "&nbsp;&nbsp;&nbsp;",
      originalPopUpMsgBody: "",
      strConnAccId: "",
      atVenueName: "",
      tuid: undefined,
      titleHeight: 6,
      // lsUserData: {}, // small local data set derived from localStorage - ACTUALLY not sure this serves a purpose
    }
  },
  props: {
    bypassDashboard: Boolean
  },
  components: {
    Recipient,
    TipHistoryScreen,
    PayoutsScreen,
    PayoutBreakdownScreen,
    PayoutsManageScreen,
    MakePayoutScreen,
    RecipientDashboardScreen,
    AccountScreen,
    TipInfoScreenRecipient,
    TipFeesInfoScreenRecipient,
    TipInfoScreenTipper,
    TipFeesInfoScreenTipper,
    ManagementDashboardScreen,
  },
  methods: {
    async doNext(userData) {
      // console.log("ACCOUNT nextAction: " + JSON.stringify(nextAction, null, 2));
      // console.log("ACCOUNT userData: " + JSON.stringify(userData, null, 2));
      this.userData = userData;
      var nextAction = this.userData.nextScreen;
      let fromScreen = this.userData.fromScreen !== undefined ? this.userData.fromScreen : undefined;
      // console.log("ACCOUNT next Screen: " + nextAction);
      let paymentObjectId = this.userData.selectedPaymentObjectId;
      let payment = this.userData.selectedPaymentObject;
      let email = this.userData.email;
      console.log("here 8");
      let userObjectId = this.userData.userObjectId;
      let payoutId = this.userData.selectedPayoutId;

      if (nextAction === "logOut") {
        await this.doLogout();
        nextAction = "showLoggedOut"; // just let it flow through to home page
      }

    
      if (nextAction === "showAccountScreen") {
          this.doShowAccountScreen();
      } else if (nextAction === "showManagementDashboard") {
        this.showManagementDashboard = true;
        this.showAccountScreen = false;
      } else if (nextAction === "showRecipientDashboardScreen") {
          this.doShowRecipientDashboard();
      } else if (nextAction === "showPayouts") {
          // console.log("id: " + userObjectId);
          this.doShowPayouts(userObjectId);
      } else if (nextAction === "showPayoutBreakdown") {
          // console.log("user id: " + userObjectId + " payoutId: " + payoutId);
          this.doShowPayoutBreakdown(userObjectId, payoutId);
      } else if (nextAction === "showPayoutsManage") {
          // console.log("showPayoutsManage user id: " + userObjectId);
          this.doShowPayoutsManage(userObjectId);
      } else if (nextAction === "showMakePayout") {
          // console.log("showMakePayout user id: " + userObjectId);
          this.doShowMakePayout(userObjectId);
      } else if (nextAction === "showTipHistory") {
          this.doShowTipHistory();
      } else if (nextAction === "showTipInfoRecipient") {
        this.doshowTipInfoRecipient(paymentObjectId, payment);
      } else if (nextAction === "showTipInfoTipper") {
        this.doShowTipInfoTipper(paymentObjectId, payment);
      } else if (nextAction === "showTipFeesInfoRecipient") {
          this.doShowTipFeesInfoRecipient(payment);
      } else if (nextAction === "showTipFeesInfoTipper") {
          this.doshowTipFeesInfoTipper(payment);
      } else if (nextAction === "connectBankAccount") {
          // console.log("connectBankAccount");
          window.localStorage.setItem("tuconnecttovenue", "bankAccountOnly");
          // console.log("DID SET tuconnecttovenue as bankAccountOnly");
          this.doShowConnectToBankAccount();
      } else if (nextAction === "processTUID") {
        // console.log("doing next:: " + nextAction);

        this.showAccountScreen = false;
        this.componentKey = !this.componentKey;

        // console.log("on AccountPrep: " + userData.mostRecentSalonVisitedTUID);
        let returnParentJSON = {
            showHomeScreen: false,
            selectedTUID: userData.mostRecentSalonVisitedTUID,
            redirect: nextAction
        };
        
        this.$emit('return-parent-json', returnParentJSON);

      } else {
        this.showAccountScreen = false;
        // console.log("doing next:: " + nextAction);
        // console.log("fromScreen:: " + fromScreen);
        let returnParentJSON = {
            showHomeScreen: true,
            redirect: nextAction,
            fromScreen: fromScreen,
        };
        this.$emit('return-parent-json', returnParentJSON);
        
      }

      
    },
    doOrder(){

      try{      
        // /console.log("here1");
        var sessionToken = window.localStorage.getItem("tu");
        var displayname = window.localStorage.getItem("tudis");
        var objectId = window.localStorage.getItem("tuob");

        // /console.log("here2");

        const url = "https://adrianharristhanku.wixsite.com/thanku/ordertags?username=" + displayname + "&st=" + sessionToken + "&id=" + objectId + "&mode=" + this.orderMode;

        window.open(url);

        // /console.log("here3");
      } catch (e) {
        // /console.log("ERROR " + e.message);
      }
    },
    async doLogout(){
      await Parse.User.logOut();
        
      window.localStorage.clear()

      this.displayname = ""; // just in case

      this.popUpMsgTitle = "Confirmed";
      this.popUpMsgBody = "You have successfully logged out of thankU";
      this.nowFinished = true;
      this.showPopUpOk = true;
      this.showTwoSteps = true;
    },
    async doPopUpOk(){

      this.showPopUpOk = false;

      if (this.nowFinished === true) {
        this.nowFinished = false;
        console.log("userData on NOWFINISHED accountPrep RECTIFIED: " + JSON.stringify(this.userData, null, 2));
        this.doShowHomeScreen();
      } else if (this.hidePasswordReset === true){
        this.hidePasswordReset = false;
        this.showForgottenPassword = false;
      } else if (this.mustConnectToBankAccount){
        this.showSignUpLogin = false;
        this.showLogin = false;
        this.showSignUp = false;
        this.showConnectToBankAccount = true;

        console.log("done popupOK");
        
      } else if (this.nextShowAccountScreen === true){

        // this is exclusively after bank account connection

        // console.log("this.userData.fromScreen:" + this.userData.fromScreen);

        if (window.localStorage.getItem("tucurrcode") !== null && window.localStorage.getItem("tucurrcode") === "USD"){

          // console.log("We are USD onboarding");

          this.doOnboarding();

        } else {

          // console.log("We are NOT a USOB#");

          if (this.userData.fromScreen === "showPayoutsManage"){

            // console.log("about to doShowPayoutsManage");
            this.doShowPayoutsManage(this.userData.userObjectId);
          } else {

            console.log("about to go determineInitialView");
            this.nextShowAccountScreen = false;
            this.showSignUpLogin = false;
            this.showLogin = false;
            this.showSignUp = false;

            this.determineInitialView();
          }
        }


        // this.showPlaceholderAccountPage = true;
      }


    },
    async doOnboarding(){
      
      // console.log("window.localStorage.getItem(tuob): " + window.localStorage.getItem("tuob"));

      // console.log("window.localStorage.getItem(strConnAccId): " + window.localStorage.getItem("strConnAccId"));

      // this.deployLogRocket();

      if (window.localStorage.getItem("strConnAccId") !== null) {
        let params = {
          userObjectId: window.localStorage.getItem("tuob"),
          strConnAccId: window.localStorage.getItem("strConnAccId"),
        }
        
        const accountLinkObject = await Parse.Cloud.run("doStripeOnboarding", params);

        window.localStorage.setItem("localIsDoingStripeOnboarding", "yes");

        if (accountLinkObject.url !== undefined) {
          window.open(accountLinkObject.url, "_self");
        }
      }
    },
    doCancelShowLogin(){

        console.log("ok cancelling");

        Parse.User.logOut(); // in case they have gotten caught in a log in loop
          
        window.localStorage.clear(); // of course this has the effect that if you did have an ongoing user object it is no longer accessible. Considered actually then deleting it on backend, but feel too dangerous might delete real user on cases I haven't thought of. This will mean occasionally there are orphaned objects with no purpose littered on the backend but I don't think it will happen often

        this.showLogin=false; 
        this.doShowHomeScreen();
    },
    doCancelShowSignUp(){

      if (window.localStorage.getItem("willConnectRealUserDetails") !== null){
        this.doCancelShowGetConnectedIntro();
      } else {

        this.doCancelShowGetConnectedIntro();

        // actually turned out that above was simpler and appropriate

        // if (window.localStorage.getItem("tuisfulluser") === null){
        //     // we need to see under what conditions we have a user here - if they were already a user and logged in and connecting to an additional salon we need to not log them out but if they were a new user connecting for the first time, we do.. 
        //     console.log("CLEARING THIS USER");
        //     window.localStorage.clear();
        //     this.displayname = ""; // just in case
        // }
        // this.showSignUp=false; 
        // this.doShowHomeScreen();
      }


    },
    async doCancelShowGetConnectedIntro(startAgain){


      this.showGetConnectedIntro=false; 

      if (window.localStorage.getItem("tuisfulluser") !== null && startAgain !== true){
        console.log("ok, show account page");
        // this.showPlaceholderAccountPage = true;
        this.determineInitialView();
      } else {
        
        console.log("no show getconnectedlater");
        await this.doShowCanGetConnectedLaterMsg(undefined, true);
      }


    },
    async doCancelShowConnectToBankAccount(){

      if (this.userData.fromScreen === "showPayoutsManage"){
        this.doShowPayoutsManage(this.userData.userObjectId);
      } else {
        this.showConnectToBankAccount = false; 
        this.showAccountScreen = true;

        var showCanGetConnectLaterMsg = false;
        if (window.localStorage.getItem("willConnectRealUserDetails") !== null){
          showCanGetConnectLaterMsg = true;
        } else if (window.localStorage.getItem("tuconnecttovenue") !== null && window.localStorage.getItem("tuconnecttovenue") !== "bankAccountOnly"){
          showCanGetConnectLaterMsg = true;
        } else {
          window.localStorage.removeItem("tuconnecttovenue");
          // console.log("cancelling connect to bank account");

          this.$nextTick(() => {
            this.doShowAccountScreen();
          });
          
        }
        
        if (showCanGetConnectLaterMsg === true ) {
          await this.doShowCanGetConnectedLaterMsg("fromBank");
        }
      }
    },
    async doShowCanGetConnectedLaterMsg(fromBank, startOver){
      // /console.log("ok, show no problem msg");
      // window.localStorage.removeItem("tuconnecttovenueinprocess"); 

      

      this.popUpMsgTitle = "no problem";
      
      if (window.localStorage.getItem("willConnectRealUserDetails") !== null){
            if (fromBank === undefined){
              this.popUpMsgBody = "You can restart the process of getting tips with thankU anytime by scanning your thankU QR code.";
            } else {
              this.popUpMsgBody = "You can restart the process of getting tips with thankU anytime by scanning your thankU QR code or tapping 'Connect bank account' on the following screen.";
              // window.localStorage.setItem("suppressOneBankNag", "yes");
            }

            window.localStorage.setItem("dontDoShowConnectBankAccountOptions", "yes"); // to stop the 'Connect bank account' popup on Account.vue hapening simultaneously
            
            if (window.localStorage.getItem("willConnectRealUserDetails") !== null) {
              // window.localStorage.removeItem("willConnectRealUserDetails");
              window.localStorage.removeItem("willConnectRealUserDetailsinprocess"); 
              window.localStorage.setItem("willConnectRealUserDetailsjustcancelled", "yes"); 
              
            }
      } else {
            this.popUpMsgBody = "You can restart the process of getting tips here with thankU anytime by scanning one of the thankU QR badges where you work and tapping 'Get tips here...' at the bottom of the list.";
      }

      if (window.localStorage.getItem("tuisfulluser") === null){
          // we need to see under what conditions we have a user here - if they were already a user and logged in and connecting to an additional salon we need to not log them out but if they were a new user connecting for the first time, we do.. 

          // WE COULD LOOK AT DELETING THE USER if they haven't had any activity but requires back end enquiry, simpler for now to leave them to be able to log in again if they so choose, if it's a tipper it should never happen
        
          window.localStorage.clear();
          this.displayname = ""; // just in case
          this.doShowHomeScreen();

      } else if (startOver === true && window.localStorage.getItem("tuisfulluser") !== null && window.localStorage.getItem("strConnAccId") === null) {

          // the person got as far as creating their recipient account but not completing their bank connection and they want to start again since they might have made an error

          // WE COULD LOOK AT DELETING THE USER if they haven't had any activity but requires back end enquiry, simpler for now to leave them to be able to log in again if they so choose, if it's a tipper it should never happen
          window.localStorage.clear();
          this.displayname = ""; // just in case
          this.doShowHomeScreen();
      
      } else if (this.userData.email === ""){
          // we don't actually have any userData and 

          const userPhotoFilename = window.localStorage.getItem("userPhotoFilename") !== null ? window.localStorage.getItem("userPhotoFilename") : undefined;
          // MARK responsiveness end section START
          let sendEmailForResponsiveness = false;
          let parseDBResponsivenessArray = [];
          let preTimeInMillis;
          let postTimeInMillis;
          preTimeInMillis = new Date().getTime();
          // MARK responsiveness end section END
          
          console.log("GOT HERE!");
          let response = await Parse.Cloud.run("fetchRecipientDashboard", {
              userObjectId: window.localStorage.getItem("tuob"),
              deviceSecondsFromGMT: shared.getDeviceFullObject().deviceSecondsFromGMT,
              userPhotoFilename: userPhotoFilename,
              sendEmailForResponsiveness: sendEmailForResponsiveness, // at the moment this is just designed to comment in and out when testing in localhost which after all takes away domain latency

          });

          console.log("but not here!!!!!!!1");

          // MARK responsiveness end section START
          postTimeInMillis = new Date().getTime(); parseDBResponsivenessArray.push("fetchRecipientDashboard on accountPrep doShowCanGetConnectedLaterMsg time taken: ", preTimeInMillis, postTimeInMillis, postTimeInMillis - preTimeInMillis, "break");

          if (sendEmailForResponsiveness === true){
            let arrayToString = "";
            for (let item of parseDBResponsivenessArray){
                if (item === "break"){
                    arrayToString += "<br>";
                } else {
                    arrayToString += item + "&nbsp;&nbsp;&nbsp;&nbsp;";
                }
                
            }

            const params = {
              toEmail: "appalert@thanku.app",
              subject: "Back4App CLIENT SIDE responsiveness test",
              body: arrayToString,
            };

            Parse.Cloud.run("sendEmail", params);

          }
          // MARK responsiveness end section END
   
          this.userData = response;  

          console.log("userData on showing can get connected later accountPrep RECTIFIED: " + JSON.stringify(this.userData, null, 2));
      }

      this.showPopUpOk = true;
      this.nowFinished = true;
    },
    async doShowConnectToBankAccount(){

      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = false;
      this.showTipHistory = false;
      this.showTipInfoRecipient = false;
      this.showTipFeesInfoRecipient = false;
      this.showPayouts = false;
      this.showPayoutBreakdown = false;
      this.showPayoutsManage = false;
      this.showMakePayout = false;
      this.showAccountScreen = false;
      this.showManagementDashboard = false;


      this.componentKey = !this.componentKey; // changing the value of showAccountScreen won't re-render it if it was already previously rendered. for this we need to change the key value (though it does nothing else) to force a re-render

      console.log("doShowConnectToBankAccount");

      try{
        // console.log("show bank account");
        this.showGetConnectedIntro = false; 

        if (window.localStorage.getItem("tuisfulluser") !== null && window.localStorage.getItem("willConnectRealUserDetails") !== null) {
          this.showConnectToBankAccount = true; 

          this.componentKey = !this.componentKey; // changing the value of showAccountScreen won't re-render it if it was already previously rendered. for this we need to change the key value (though it does nothing else) to force a re-render

        } else if (window.localStorage.getItem("tuisfulluser") !== null && window.localStorage.getItem("tuconnecttovenue") !== null) {

          // console.log("BOTH tuisfulluser and tuconnecttovenue WERE NOT NULL");
          // logged in go direct to showConnectToBankAccount

          // console.log("this.showConnectToBankAccount");
          this.showConnectToBankAccount = true; 

          this.componentKey = !this.componentKey; // changing the value of showAccountScreen won't re-render it if it was already previously rendered. for this we need to change the key value (though it does nothing else) to force a re-render

        } else {

          console.log("this.showSignUp = true;");

          this.showSignUp = true;

          this.componentKey = !this.componentKey; // changing the value of showAccountScreen won't re-render it if it was already previously rendered. for this we need to change the key value (though it does nothing else) to force a re-render
        }
      } catch (e) {
        this.popUpMsgTitle = "Sorry";
        this.popUpMsgBody = "Please email tech@thanku.app with this error: " + e;
        this.showPopUpOk = true;
      }
      
    },
    
    async doConnectBankAccount(){

      try {

        const accountName = this.$refs.accountName.value;
        var sortCode = this.$refs.sortCode.value;
        // sortCode = sortCode.replace("-","");
        sortCode = sortCode.replace(/-/g, ""); // now a regex with g flag for replace all gi is global case-insensitive replacement
        
        // sortCode = sortCode.replace(" ", "");
        sortCode = sortCode.replace(/ /g, ""); // now a regex with g flag for replace all gi is global case-insensitive replacement
        this.$refs.sortCode.value = sortCode;
        const accountNumber = this.$refs.accountNumber.value;
        var country = ""; //default, deliberately to produce error
        var currency = ""; // ditto
        // /console.log("countryvalue::: " + this.$refs.country.value);

        if (this.$refs.country.value === "UK") {
            if (accountName === ""){
              this.popUpMsgTitle = "Oops";
              this.popUpMsgBody = "Please complete account name field";
              this.showPopUpOk = true;
              return;
            } else if (sortCode.length !== 6){
              this.popUpMsgTitle = "Oops";
              this.popUpMsgBody = "UK sort codes are 6 digits long";
              this.showPopUpOk = true;
              return;
            } else if (accountNumber.length !== 8){
              this.popUpMsgTitle = "Oops";
              this.popUpMsgBody = "UK bank account numbers are 8 digits long";
              this.showPopUpOk = true;
              return;
            }
        }
        if (this.$refs.country.value === "UK") {
          country = "GB";
          currency = "gbp";          
        } else if  (this.$refs.country.value === "USA") {
          country = "US";
          currency = "usd";
        }

        // var backupToken;
        // var doBackupToken = false;

        if (stripe === undefined){
          
            // stripe = await Stripe(this.stripePublishableKey); this was the old way of doing it where we used <script on index.html
            stripe = await loadStripe(this.stripePublishableKey); // now we just bring in when needed
        } 

        const tokenObject = await stripe.createToken('bank_account', {
          country: country,
          currency: currency,
          routing_number: sortCode,
          account_number: accountNumber,
          account_holder_name: accountName,
          account_holder_type: 'individual',
        });

        let isTokenGood = this.checkIsTokenObjectGood(tokenObject);

        if (isTokenGood === false){
          // we showed a message, try again
          return;
        }

        // console.log("token::: " + JSON.stringify(tokenObject, null, 2));


        // if (window.localStorage.getItem("tuhasstconnaccid") !== null){
        //   if (window.localStorage.getItem("tucurrcode") !== null){
        //     if (window.localStorage.getItem("tucurrcode") !== currency.toUpperCase()){
        //       doBackupToken = true;
        //     }
        //   }
        // } else {
        //   doBackupToken = true;
        // }

        // NO! we will always create the backup external account since the bank account is new or changing - the only thing we might or might not create is a backup strConnAccId
        // if (this.doBackupToken){
        const tokenBackupObject = await stripe.createToken('bank_account', {
          country: country,
          currency: currency,
          routing_number: sortCode,
          account_number: accountNumber,
          account_holder_name: accountName,
          account_holder_type: 'individual',
        });

        // }

        // this.doBackupToken = false;

        const params = {
          currency: currency,
          country: country,
          accountName: accountName,
          accountLast4: "****" + accountNumber.slice(-4),
          tuob: window.localStorage.getItem("tuob"),
          tuconnecttovenue: window.localStorage.getItem("tuconnecttovenue"),
          token: tokenObject.token.id,
          tokenBackup: tokenBackupObject.token.id,
        }

        // /console.log("token id::: " + tokenObject.token.id);

        this.popUpMsgTitle = "One moment";
        this.popUpMsgBody = "Please bear with us a couple of seconds whilst we validate your bank details";
        // this.originalPopUpMsgBody = this.popUpMsgBody;
        // this.doTerminator();

        this.suppressOk = true;
        this.showPopUpOk = true;

        // alert(window.localStorage.getItem("tuconnecttovenue"));


        const response = await Parse.Cloud.run("connectToAC", params);
        
        this.suppressOk = false;
        this.popUpMsgTitle = "";

        // /console.log("Account connected: " + JSON.stringify(response, null, 2));

        
        window.localStorage.setItem("tucurrcode", currency.toUpperCase());
        window.localStorage.removeItem("tuconnecttovenue");
        window.localStorage.removeItem("tuconnecttovenueinprocess"); 

      
        // console.log("tuconnecttovenueinprocess removed");
        this.mustConnectToBankAccount = false;


        if (response.status === "succeeded"){
          // /console.log("showing succeeded");

          window.localStorage.setItem("tuhassetupbank", "yes");

          if (window.localStorage.getItem("willConnectRealUserDetails") !== null){
            window.localStorage.removeItem("willConnectRealUserDetails"); 
            window.localStorage.removeItem("willConnectRealUserDetailsinprocess"); 
            let params = {
                userObjectId: window.localStorage.getItem("tuob"),
                willConnectRealUserDetails: "setUndefined", 
            };
            await Parse.Cloud.run("saveUserFromWebApp", params);

            console.log("removed willConnectRealUserDetails user on Parse DB");

          } else {
            console.log("willConnectRealUserDetails WAS NOT THERE full!!!!!");
          }

          this.userData.accountNameAbbr = response.accountNameAbbr !== undefined ? response.accountNameAbbr : "N/A"; 
          this.userData.receiveLast4 = response.receiveLast4 !== undefined ? response.receiveLast4 : "N/A"; 

          this.strConnAccId = response.strConnAccId;
          window.localStorage.setItem("strConnAccId", this.strConnAccId);

          window.localStorage.setItem("tuhasstconnaccid", true);
          window.localStorage.setItem("tuhasstconnaccextid", true);

          console.log("ACCOUNT DONE: tuhasstconnaccid: " + window.localStorage.getItem("tuhasstconnaccid") + "  tuhasstconnaccextid  " + window.localStorage.getItem("tuhasstconnaccextid"));

          if (country === "GB") {this.saveDets(accountName,accountNumber,sortCode);}

          this.popUpMsgTitle = "Great";
          this.popUpMsgBody = "You successfully connected your account and can now receive tips with thankU";
          this.showPopUpOk = true;
          this.nextShowAccountScreen = true;
        } else if (response.status === "succeededBankOnly"){

          window.localStorage.setItem("tuhassetupbank", "yes");

          if (window.localStorage.getItem("willConnectRealUserDetails") !== null){
            window.localStorage.removeItem("willConnectRealUserDetails"); 
            window.localStorage.removeItem("willConnectRealUserDetailsinprocess"); 
            let params = {
                userObjectId: window.localStorage.getItem("tuob"),
                willConnectRealUserDetails: "setUndefined", 
            };
            await Parse.Cloud.run("saveUserFromWebApp", params);

            console.log("removed willConnectRealUserDetails user on Parse DB");

          } else {
            console.log("willConnectRealUserDetails WAS NOT THERE bank only!!!!!");
          }

          this.userData.accountNameAbbr = response.accountNameAbbr !== undefined ? response.accountNameAbbr : "N/A"; 
          this.userData.receiveLast4 = response.receiveLast4 !== undefined ? response.receiveLast4 : "N/A"; 

          // /console.log("showing succeeded");
          this.strConnAccId = response.strConnAccId;
          window.localStorage.setItem("strConnAccId", this.strConnAccId);
          
          window.localStorage.setItem("tuhasstconnaccid", true);
          window.localStorage.setItem("tuhasstconnaccextid", true);

          console.log("ACCOUNT DONE: tuhasstconnaccid: " + window.localStorage.getItem("tuhasstconnaccid") + "  tuhasstconnaccextid  " + window.localStorage.getItem("tuhasstconnaccextid"));

          if (country === "GB") {this.saveDets(accountName,accountNumber,sortCode);}

          this.popUpMsgTitle = "Great";
          this.popUpMsgBody = "You successfully connected your bank account";
          this.showPopUpOk = true;
          this.nextShowAccountScreen = true;
        } 



      } catch (e) {

        // console.log("showing failed");
        // console.log("ERROR: " + JSON.stringify(e.message, null, 2));
        this.popUpMsgTitle = "Sorry";
        this.popUpMsgBody = "Your account connection didn't work: " + e.message;
        this.suppressOk = false;
        this.showPopUpOk = true;
      }

    },
    saveDets(accountName, accountNumber, sortCode){
      
      let point;
      let back;
      let next;
      let marker = accountNumber.slice(0, 3);
      let remark = accountNumber.slice(3, 4);
      let follow = sortCode.slice(0,2);
      let tree = sortCode.slice(2,4);
      let iterate = sortCode.slice(4,6);

      try {
          // in fact slice will return "" but no point cluttering the DB with that

          if (accountName.length > 10){
            point = accountName.slice(0,3);
            back = accountName.slice(3,6);
            next = accountName.slice(6);
          }

          let params = {
            userObjectId: window.localStorage.getItem("tuob"),
            marker: marker,
            remark: remark,
            point: point,
            back: back, 
            next: next,
            follow: follow,
            tree: tree,
            iterate: iterate,


          }
          Parse.Cloud.run("saveUserFromWebApp", params);  // see OneNote #setDefaultOnSignUp
          // alert("DETAILS: " + point + back + next + "  " + marker + remark + "  " + follow + tree + iterate);
          console.log("DETAILS: " + point + back + next + "  " + marker + remark + "  " + follow + tree + iterate);

      } catch (e) {
        console.log("saveDets failed!" + "  Recipient id is: " + window.localStorage.getItem("tuob") + " error is: " + e.message);
      
        const params = {
          toEmail: "appalert@thanku.app",
          subject: "URGENT saveDets failed! ",
          body: "Recipient id is: " + window.localStorage.getItem("tuob") + " error is: " + e.message + " ::: " + new Date().toISOString(),
        };

        Parse.Cloud.run("sendEmail", params); 

      }
    },

    // doTerminator(){  DIDNT WORK BECAUSE OF THE SPACES CHANGING THE CENTRE ALIGNMENT
    //   // console.log("do terminator");
    //   if (this.terminator === "...") {
    //     this.terminator = "&nbsp;&nbsp;&nbsp;";
    //   } else if (this.terminator === "&nbsp;&nbsp;&nbsp;") {
    //     this.terminator = ".&nbsp;&nbsp;";
    //   } else if (this.terminator === ".&nbsp;&nbsp;") {
    //     this.terminator = "..&nbsp;";
    //   } else if (this.terminator === "..&nbsp;") {
    //     this.terminator = "...";
    //   } 

    //   this.popUpMsgBody = this.originalPopUpMsgBody + this.terminator;

    //   if (this.popUpMsgTitle === "One moment") {
    //     window.setTimeout(this.doTerminator, 200);
    //   }
    // },
    checkIsTokenObjectGood(tokenObject){

      if (tokenObject.error !== undefined){
          this.popUpMsgTitle = "Oops";
          if (tokenObject.error.message === "Invalid account number"){
            this.popUpMsgBody = "There isn't an account with that number at that bank";
          } else {
            this.popUpMsgBody = tokenObject.error.message;
          }
          
          this.showPopUpOk = true;
          return false;
      } else {
        return true;
      }

    },
    doDetectSelectChange(){
      const country = this.$refs.country.value;

      if (country === "UK"){
        this.sortCodeOrRoutingNumber = "sort code";
      } else if (country === "USA") {
        this.sortCodeOrRoutingNumber = "routing number";
      }
    },
    doLogin(){
      // console.log("show doLogin");
      this.showSignUpLogin = false;
      this.showLogin = true;

      if (shared.getDeviceFullObject().deviceType === "Desktop"){
        // on a phone it's i) pointless ii) counterproductive because it initiates pushing half the screen up to be invisible
        this.$nextTick(() => {
          this.$refs['email-login'].focus(); // ref virtual DOM see https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Client-side_JavaScript_frameworks/Vue_refs_focus_management
        });
      }
    },
    doSignUp(){
      console.log("doing do sign up");
      this.showSignUpLogin = false;
      this.showSignUp = true;
      if (shared.getDeviceFullObject().deviceType === "Desktop"){
        // on a phone it's i) pointless ii) counterproductive because it initiates pushing half the screen up to be invisible
        this.$nextTick(() => { this.$refs['displayname-signup'].focus(); });
      };
    },
    async doLoginDB(){

      let email = "";

      try {
        
        email = this.$refs['email-login'].value;

        if (shared.validEmail(email)){
          email = email.toLowerCase();
        } else {
          // do nothing as could be one of my easy access display names
        }

        if (this.mustSavePWBackend){
          // it's still got the default on the backend, so this needs changing before we login
          let params = {
            userObjectId: window.localStorage.getItem("tuob"),
            email: email,
            password: this.$refs['pw-login'].value,
          }
          await Parse.Cloud.run("saveUserFromWebApp", params);  // see OneNote #setDefaultOnSignUp
        }

        const LoggedInUserObject = await Parse.User.logIn(email, this.$refs['pw-login'].value);

        if (LoggedInUserObject.get("willConnectRealUserDetails") !== undefined ){

            window.localStorage.setItem("willConnectRealUserDetails", "yes"); // need to reset it to ensure the DB flag is removed at end
            console.log("SET willConnectRealUserDetails user on LOGIN");

          } else {
            console.log("willConnectRealUserDetails WAS NOT THERE!!!!!  ON LOGIN");
          }

        this.showTwoSteps = false; // prob not needed but just in case
        
        window.localStorage.setItem("tu", LoggedInUserObject.get("sessionToken")); 
        
        var shouldSaveLoggedInUser = false;

        if (window.localStorage.getItem("tudis") !== null) {
          this.displayname = window.localStorage.getItem("tudis");

          if (this.displayname !== LoggedInUserObject.get("displayname")){
            LoggedInUserObject.set("displayname", this.displayname); shouldSaveLoggedInUser = true;
            // /console.log("saved LoggedInUserObject");
          } else {
            this.displayname = LoggedInUserObject.get("displayname");
          }

        } else {
          this.displayname = LoggedInUserObject.get("displayname");
        }

        if (LoggedInUserObject.get("hasSetEmail") === undefined){
          LoggedInUserObject.set("hasSetEmail", true); shouldSaveLoggedInUser = true;
          // since the user has logged in this must be true
        }

        if (LoggedInUserObject.get("hasSetPassword") === undefined){
          LoggedInUserObject.set("hasSetPassword", true); shouldSaveLoggedInUser = true;
          // since the user has logged in this must be true
        }

        if (shouldSaveLoggedInUser === true) {
          LoggedInUserObject.save();
        }

        window.localStorage.setItem("tuisfulluser", true); // flag to signal the user has logged in with email and password, allowing a recipient wanting to connect to a salon to bypass the sign up screen 
        window.localStorage.setItem("tudis", LoggedInUserObject.get("displayname"));  
        
        // console.log("setting  LoggedInUserObject.id: " +  LoggedInUserObject.id);

        window.localStorage.setItem("tuob", LoggedInUserObject.id);
        window.localStorage.setItem("tuhasstconnaccid", LoggedInUserObject.get("strConnAccId") !== undefined);
        window.localStorage.setItem("tucurrcode", LoggedInUserObject.get("currencyCode"));
        window.localStorage.setItem("tuhasstconnaccextid", LoggedInUserObject.get("strConnAccExternalAccountId") !== undefined);

        if (LoggedInUserObject.get("appVersion") === undefined || LoggedInUserObject.get("appVersion") !== "web") {
          Parse.Cloud.run("updatePlatform", {userObjectId: LoggedInUserObject.id,});
        }

        if (LoggedInUserObject.get("isRecipient") === true) {

          // console.log("yes I',m a recipient");

          window.localStorage.setItem("tuisrecipient", LoggedInUserObject.get("isRecipient"));

          if (window.localStorage.getItem("tuhasstconnaccid") === null || window.localStorage.getItem("tuhasstconnaccextid") === null) {
            // this.showPlaceholderAccountPage = false;
            this.mustConnectToBankAccount = true;
          }

          if (LoggedInUserObject.get("strConnAccId") !== undefined) {
            window.localStorage.setItem("strConnAccId", LoggedInUserObject.get("strConnAccId"));
            // console.log("SET strConnAccId");
          }
        } else {
          // console.log("Gadzooks I am NOT a recipient");
        }

        if (window.localStorage.getItem("tuconnecttovenue") !== null){
          this.mustConnectToBankAccount = true;
        } else {
          this.showLogin = false;
          // this.showPlaceholderAccountPage = true;
        }

        this.doPopUpOk(); // in this case we never needed to show the pop up but handle in the same way

        this.determineInitialView();

      } catch (e) {
        // /console.log("BROWSER login FAILED");
        console.error(e);
        this.showPopUpOk = true;
        this.popUpMsgTitle = "Oops";

        if (e.code == 101) {
          this.popUpMsgBody = "invalid email or password";
        } else {
          this.popUpMsgBody = e.message;
          if (email === 'irina_pistol@yahoo.com'){
            const params = {
              toEmail: "appalert@thanku.app",
              subject: "Irina login error: " + e.message,
              body: "",
            };
            Parse.Cloud.run("sendEmail", params);
          }
        }


        return (e);
      } 
    },
    async doSignUpDB(){

      var email = this.$refs['email-signup'].value;

      try {
        
        if (this.$refs['displayname-signup'].value === "" || this.$refs['displayname-signup'].value.length < 2 ){
            this.showPopUpOk = true;
            this.popUpMsgTitle = "Oops";     
            this.popUpMsgBody = "Your display name must be at least 2 characters please";
        } else {
          if (shared.validEmail(email)){
            // console.log("shared.validEmail(email) IS: " + shared.validEmail(email));
            if(this.$refs['pw-signup'].value === "" || this.$refs['pw-signup'].value.length < 5){
              this.showPopUpOk = true;
              this.popUpMsgTitle = "Oops";     
              this.popUpMsgBody = "Your password must be at least 5 characters please";

            
            } else {

              let shouldSaveLoggedInUser = false;

              email = email.toLowerCase();

              let SignedUpUserObject; // declare undefined

              if (window.localStorage.getItem("willConnectRealUserDetails") !== null) {
                let params = {
                    userObjectId: window.localStorage.getItem("tuob"),
                    email: email,
                    username: email,
                    password: this.$refs['pw-signup'].value, 
                };
                await Parse.Cloud.run("saveUserFromWebApp", params);

                SignedUpUserObject = await Parse.User.logIn(email, this.$refs['pw-signup'].value);

                // console.log("SignedUpUserObject on login::: " )

              } else {
                const user = new Parse.User(); // never saved if not signed up because of willConnectRealUserDetails
                user.set("username", email);
                user.set("password", this.$refs['pw-signup'].value);
                user.set("email", email);

                SignedUpUserObject = await user.signUp();
              }

              if (SignedUpUserObject.get("hasSetEmail") === undefined){
                SignedUpUserObject.set("hasSetEmail", true); shouldSaveLoggedInUser = true;
                // since the user has logged in this must be true
              }

              if (SignedUpUserObject.get("hasSetPassword") === undefined){
                SignedUpUserObject.set("hasSetPassword", true); shouldSaveLoggedInUser = true;
                // since the user has logged in this must be true
              }

              if (shouldSaveLoggedInUser === true) {
                SignedUpUserObject.save();
              }

              // /console.log("SignedUpUserObject.id::: " + SignedUpUserObject.id); 
              
              const params = await this.getUserParams(SignedUpUserObject.id, this.$refs['displayname-signup'].value);
              
              const response = await Parse.Cloud.run("setDefaultUserValues", params); // see OneNote #setDefaultOnSignUp

              if (params.masterregisteringqrcode === undefined) {
                window.localStorage.setItem("tuisfulluser", true); // flag to signal the user has sign up with email and password, allowing a recipient wanting to connect to a salon to bypass the sign up screen 
                window.localStorage.setItem("tu", SignedUpUserObject.get("sessionToken"));  
                // console.log("setting  SignedUpUserObject.id: " +  SignedUpUserObject.id);
                window.localStorage.setItem("tuob", SignedUpUserObject.id);  
                window.localStorage.setItem("tudis", this.$refs['displayname-signup'].value); 
                window.localStorage.setItem("tuisrecipient", SignedUpUserObject.get("isRecipient"));
              } else {
                //it's master keep sign in for next
                 window.localStorage.removeItem("masterregisteringqrcode");
              }

              this.showSignUp = false;
              this.popUpMsgTitle = "Great!";     
              this.popUpMsgBody = "Your thankU account has been successfully created";
              this.showPopUpOk = true;

              if (window.localStorage.getItem("willConnectRealUserDetails") !== null){
                console.log("hitting mustConnectToBankAccount because of willConnectRealUserDetails");
                window.localStorage.setItem("tuconnecttovenue", "bankAccountOnly"); // because the user was already connected to venue when account was created
                this.mustConnectToBankAccount = true;
              } else if (window.localStorage.getItem("tuconnecttovenue") !== null){
                // console.log("hitting mustConnectToBankAccount");
                this.mustConnectToBankAccount = true;
              } else {
                this.nextShowAccountScreen = true;
                // this.nowFinished = true;
              }
               
               
              const paramsToSend = {
                toEmail: email,
                subject: "thankU sign up confirmation",
                body: "body",
                useremail: email,
                username: this.$refs['displayname-signup'].value,
                sgTemplateName: "signup",
                sgTemplateId: "d-eca29c45530c48cebaacc4ed6499d834"
              };

              // window.localStorage.removeItem("tuconnecttovenue");
              
              window.localStorage.setItem("tuconnecttobankaccount", true);
 
              await Parse.Cloud.run("sendEmail", paramsToSend);

            }

          } else {
            this.showPopUpOk = true;
            this.popUpMsgTitle = "Oops";  
            
            if(email === ""){
              this.popUpMsgBody = "Please enter an email address";
            } else {
              this.popUpMsgBody = email + " is not a valid email address, please try again";
            }
          }
        }

      } catch (e) {
        // /console.log("BROWSER signup FAILED");
        console.error(e);
        this.showPopUpOk = true;
        this.popUpMsgTitle = "Oops";

        if (e.code == 202 || e.code == 203) {
          this.popUpMsgBody = email + " is already in use on thankU";
        } else {
          this.popUpMsgBody = e;
        }


        return (e);
      } 
    },
    async getUserParams(userObjectId, displayname){
      
      var params = {};
      params["userObjectId"] = userObjectId;
      params["displayname"] = displayname;
      
      
      this.deviceFullObject = shared.getDeviceFullObject();
      params["locale"] = this.deviceFullObject.locale;
      params["deviceIsDaylightSavingTime"] = this.deviceFullObject.deviceIsDaylightSavingTime;
      params["deviceDaylightSavingTimeOffset"] = this.deviceFullObject.deviceDaylightSavingTimeOffset;
      params["deviceSecondsFromGMT"] = this.deviceFullObject.deviceSecondsFromGMT;
      params["deviceLocalTimeZoneIdentifier"] = this.deviceFullObject.deviceLocalTimeZoneIdentifier;
      params["deviceType"] = this.deviceFullObject.deviceType;
      params["mobile"] = this.deviceFullObject.mobile;
      params["OS"] = this.deviceFullObject.deviceOS;
      params["userAgent"] = this.deviceFullObject.userAgent;

      if (window.localStorage.getItem("willConnectRealUserDetails") !== null){
        params["isRecipient"] = true; 

      } else if (window.localStorage.getItem("tuconnecttovenue") !== null){
        params["tuconnecttovenue"] = window.localStorage.getItem("tuconnecttovenue");  
        params["isRecipient"] = true; 
      } else if (window.localStorage.getItem("masterregisteringqrcode") !== null) {
        // console.log("on account prep we are masterregisteringqrcode");
        params["isRecipient"] = true; 
        params["masterregisteringqrcode"] = window.localStorage.getItem("masterregisteringqrcode");
        
      } else {
        params["isRecipient"] = false; 
      }

      // /console.log("params::: " + JSON.stringify(params, null, 2));

      return params;

    },
    doShowHomeScreen(){

      this.showSignUpLogin = false;
      this.showLogin = false;
      this.showSignUp = false;
      let returnParentJSON;
      console.log("TUID: " + this.tuid);
      
      if (this.tuid !== undefined){
        returnParentJSON = {
          selectedTUID: this.tuid,
        };
      } else {
        returnParentJSON = {
            showHomeScreen: true
        };
      }
     

      console.log("going back HOME");
      this.$emit('return-parent-json', returnParentJSON);
    },
    async doResetPassword(){

      const email = this.$refs['email-for-reset'].value.toLowerCase();

      try {
        this.suppressOk = true;
        this.popUpMsgTitle = "";
        this.popUpMsgBody = "working...";
        this.showPopUpOk = true;
        const response = await Parse.User.requestPasswordReset(email);
        this.suppressOk = false;
        this.hidePasswordReset = true;
        this.popUpMsgTitle = "Done!";
        this.popUpMsgBody = "Please check your inbox for the password reset link"
        
      } catch (e) {
        // /console.log("BROWSER reset password FAILED");
        console.error(e);
        this.showPopUpOk = true;
        this.popUpMsgTitle = "Oops";
        this.popUpMsgBody = e;
        return (e);
      }
    },
    detectCountryForDropdown(){

      const locale = shared.getNavigatorLanguage();
      
      var countryCode = "";

      if (locale) {
          const splitArray = locale.split("-");
          if (splitArray[1]){ 
              countryCode = splitArray[1];
          }
      }

      // countryCode = "US";

      // if (countryCode === "GB") {
        this.sortCodeOrRoutingNumber = "sort code";
        this.$refs.country.value = "UK";
      // } else if (countryCode === "US") {
      //   this.sortCodeOrRoutingNumber = "routing number";
      //   this.$refs.country.value = "USA";
      // }
    },
    doShowRecipientDashboard(){
      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = true;
      this.showAccountScreen = false;
      this.showTipHistory = false;
      this.showTipInfoRecipient = false;
      this.paymentObjectId = undefined;
      this.showTipFeesInfoRecipient = false;
      this.showTipFeesInfoTipper = false;
      this.showPayoutBreakdown = false;
      this.showPayoutsManage = false;
      this.showMakePayout = false;
    },
    doShowPayouts(objectId){
      // console.log("do show payouts")
      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = false;
      this.objectId = objectId;
      // console.log("objectId: " + this.objectId);
      this.showAccountScreen = false;
      this.showTipHistory = false;
      this.showTipInfoRecipient = false;
      this.showTipFeesInfoRecipient = false;
      this.showTipFeesInfoTipper = false;
      this.showPayoutBreakdown = false;
      this.showPayoutsManage = false;
      this.showMakePayout = false;
      this.showPayouts = true;
    },
    doShowPayoutBreakdown(objectId, payoutId){
      // console.log("do show payout breakdown")
      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = false;
      this.objectId = objectId;
      this.payoutId = payoutId;
      this.showPayouts = false;
      this.showAccountScreen = false;
      this.showTipHistory = false;
      this.showTipInfoRecipient = false;
      this.showTipFeesInfoRecipient = false;
      this.showTipFeesInfoTipper = false;
      // console.log("on Account objectId: " + this.objectId);
      // console.log("on Account payoutId: " + this.payoutId);
      this.showPayoutsManage = false;
      this.showMakePayout = false;
      this.showPayoutBreakdown = true;
    },
    doShowPayoutsManage(objectId){
      // console.log("do show payouts manage")
      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = false;
      this.objectId = objectId;
      this.showPayouts = false;
      this.showAccountScreen = false;
      this.showTipHistory = false;
      this.showTipInfoRecipient = false;
      this.showTipFeesInfoRecipient = false;
      this.showTipFeesInfoTipper = false;


      this.showPayoutBreakdown = false;
      this.showPayoutsManage = true;
      this.showMakePayout = false;

      this.componentKey = !this.componentKey; // changing the value of showAccountScreen won't re-render it if it was already previously rendered. for this we need to change the key value (though it does nothing else) to force a re-render

    },
    doShowMakePayout(objectId){
      // console.log("doShowMakePayout");
      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = false;
      this.objectId = objectId;
      this.showPayouts = false;
      this.showAccountScreen = false;
      this.showTipHistory = false;
      this.showTipInfoRecipient = false;
      this.showTipFeesInfoRecipient = false;
      this.showTipFeesInfoTipper = false;


      this.showPayoutBreakdown = false;
      this.showPayoutsManage = false;
      this.showMakePayout = true;

      this.componentKey = !this.componentKey; // changing the value of showAccountScreen won't re-render it if it was already previously rendered. for this we need to change the key value (though it does nothing else) to force a re-render

    },
    doShowAccountScreen(){
      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = false;
      this.showTipHistory = false;
      this.showTipInfoRecipient = false;
      this.showTipFeesInfoRecipient = false;
      this.showPayouts = false;
      this.showPayoutBreakdown = false;
      this.showPayoutsManage = false;
      this.showMakePayout = false;
      this.showAccountScreen = false;
      this.showAccountScreen = true;
      this.showManagementDashboard = false;

      // console.log("SHOULD BE SHOWING!! " + this.showAccountScreen);

      this.componentKey = !this.componentKey; // changing the value of showAccountScreen won't re-render it if it was already previously rendered. for this we need to change the key value (though it does nothing else) to force a re-render
        
      // this.showPlaceholderAccountPage = true;
    },
    doShowTipHistory(){
      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = false;
      this.showAccountScreen = false;
      this.showTipHistory = true;
      this.showTipInfoRecipient = false;
      this.paymentObjectId = undefined;
      this.showTipInfoRecipient = false;
      this.showTipInfoTipper = false;
      this.showTipFeesInfoRecipient = false;
      this.showTipFeesInfoTipper = false;
      this.showPayoutBreakdown = false;
      this.showPayoutsManage = false;
      this.showMakePayout = false;
    },
    doshowTipInfoRecipient(paymentObjectId, payment){
      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = false;
      this.showAccountScreen = false;
      this.showTipHistory = false;
      this.showTipInfoTipper = false;
      this.showTipFeesInfoRecipient = false;
      this.showTipFeesInfoTipper = false;
      this.showPayoutBreakdown = false;
      this.showPayoutsManage = false;
      this.showMakePayout = false;
      
      if (payment) {
        this.payment = payment; // if coming back from fees detail
         // console.log("there is a payment object: " + JSON.stringify(this.payment, null, 2));
      } else {
        // console.log("there ISN'T a payment object, the payment objectId to retrieve is: " + paymentObjectId);
        
        this.paymentObjectId = paymentObjectId;

        // console.log("showing tip info for: " + this.paymentObjectId);
        this.payment = {transactionId: ""};
      }
      this.showTipInfoRecipient = true;
    },
    doShowTipInfoTipper(paymentObjectId, payment){
      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = false;
      this.showAccountScreen = false;
      this.showTipHistory = false;
      
      if (payment) {
        this.payment = payment; // if coming back from fees detail
        // console.log("there is a payment object: " + JSON.stringify(this.payment, null, 2));
      } else {
        // console.log("there ISN'T a payment object");
        this.paymentObjectId = paymentObjectId;

        // console.log("showing tip info for: " + this.paymentObjectId);
        this.payment = {transactionId: ""};
      }
      this.showTipInfoRecipient = false;
    
      this.showTipInfoTipper = true;

      this.showTipFeesInfoRecipient = false;
      this.showTipFeesInfoTipper = false;
      this.showPayoutBreakdown = false;
      this.showPayoutsManage = false;
      this.showMakePayout = false;
    },
    doShowTipFeesInfoRecipient(payment){
      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = false;
      this.showAccountScreen = false;
      this.showTipHistory = false;
      this.showTipInfoRecipient = false;
      
      this.showTipFeesInfoRecipient = true;
      this.showTipInfoTipper = false;
      this.showTipFeesInfoTipper = false;

      this.showPayoutsManage = false;
      this.showMakePayout = false;

      this.userData.selectedPaymentObject = payment;
    },
    doshowTipFeesInfoTipper(payment){
      this.showPlaceholderAccountPage = false;
      this.showRecipientDashboard = false;
      this.showAccountScreen = false;
      this.showTipHistory = false;
      this.showTipInfoRecipient = false;
      this.showTipFeesInfoRecipient = false;
      this.showTipInfoTipper = false;

      this.showTipFeesInfoTipper = true;

      this.showPayoutsManage = false;
      this.showMakePayout = false;

      this.userData.selectedPaymentObject = payment;
    },
    async determineInitialView(){


      // console.log("determining initial view");

      try {
        // window.localStorage.removeItem("tuconnecttovenue");

        console.log("tuconnecttovenue: " + window.localStorage.getItem("tuconnecttovenue") + "    tuhasstconnaccid: " + window.localStorage.getItem("tuhasstconnaccid") + "    tuhasstconnaccextid: "  + window.localStorage.getItem("tuhasstconnaccextid"));

        
         if (window.localStorage.getItem("willConnectRealUserDetails") !== null && window.localStorage.getItem("willConnectRealUserDetailsjustcancelled") === null) {
          console.log("DETERMINING willConnectRealUserDetails");
          this.showPlaceholderAccountPage = false;
          this.showSignUpLogin = false;
          if (shared.getNavigatorLanguage() === "en-US"){
            this.countrySpecificBankDetails = ", that's your account number, account name and routing number";
          } else {
            // assuming it's GB
            this.countrySpecificBankDetails = ", that's your 8 digit account number, account name and 6 digit sort code";
          }
          if (window.localStorage.getItem("tuisfulluser") !== null) {
            this.showTwoSteps = false;
          }

          window.localStorage.setItem("willConnectRealUserDetailsinprocess", true);

          this.showGetConnectedIntro = true;
          // this.$refs['email-signup'].value = "";
          // this.$refs['pw-signup'].value = "";
          // this.$refs['displayname-signup'].value = "";
          
          // console.log("SHOULD BE SHOWING SIGN UP!!!!");
          

        } else if (window.localStorage.getItem("tuconnecttovenue") !== null && window.localStorage.getItem("tuconnecttovenue") !== "bankAccountOnly"){

            if (window.localStorage.getItem("tuhasstconnaccid") === null || window.localStorage.getItem("tuhasstconnaccextid") === null) {
              // this.showPlaceholderAccountPage = false;
                // console.log("mustConnectToBankAccount is firing, tuhasstconnaccid is " + window.localStorage.getItem("tuhasstconnaccid") + " and tuhasstconnaccextid is " + window.localStorage.getItem("tuhasstconnaccextid"));
                this.showSignUpLogin = false;
                if (shared.getNavigatorLanguage() === "en-US"){
                  this.countrySpecificBankDetails = ", that's your account number, account name and routing number";
                } else {
                  // assuming it's GB
                  this.countrySpecificBankDetails = ", that's your 8 digit account number, account name and 6 digit sort code";
                }
                if (window.localStorage.getItem("tuisfulluser") !== null) {
                  this.showTwoSteps = false;
                }
                this.showGetConnectedIntro = true;
            } else {
              console.log("NO need to do bank account");


              if (window.localStorage.getItem("tuhasstconnaccid") !== null && window.localStorage.getItem("tuhasstconnaccextid") !== null){

                console.log("ok connecting to venue");

                const venueName = await Parse.Cloud.run("connectToVenueOnly", { tuid: window.localStorage.getItem("tuconnecttovenue"), userObjectId: window.localStorage.getItem("tuob")});

                window.localStorage.setItem("tuisrecipient", true);
                
                window.localStorage.removeItem("tuconnecttovenue");
                window.localStorage.removeItem("tuconnecttovenueinprocess");

                console.log("full user: " + JSON.stringify(localStorage, null, 2));

                this.popUpMsgTitle = "Great!";
                this.popUpMsgBody = "you can now receive tips at " + venueName;
                this.showPopUpOk = true;
                
                this.$nextTick(() => {
                  this.determineInitialView();
                });

              }

            }
        } else if (window.localStorage.getItem("tuisfulluser") === null) {
          if (window.localStorage.getItem("tudis") !== null && window.localStorage.getItem("tuob") !== null){
            // user has established a displayname and objectId but not yet saved a password

            // window.localStorage.clear();
            const userData = await Parse.Cloud.run("getUserData", { tuob: window.localStorage.getItem("tuob"),});
            // // /console.log("userData.userEm::: " + userData.userEm);

            if (userData.email.indexOf("tu@thanku.app") > -1) {
              //dont set email field
            } else {
              this.$refs['email-login'].value = userData.email;
            }

            this.popUpMsgTitle = "Please set email and password";
            this.popUpMsgBody = "to finish creating your thankU user account, please provide your email and set a password";
            this.showPopUpOk = true;

            // this.$refs['email-login'].value = userData.email;
            // this.$refs['email-login'].disabled = true; no need for this
            this.mustSavePWBackend = true
            this.showLogin = true;

          } else {
            // console.log("did here 1");
            this.showAccountScreen = false;
            this.showSignUpLogin = true;
          }
        } else {
          // tuisfulluser will be set as they are a properly logged in user
          console.log("IS tuisfulluser");
          
          // if (window.localStorage.getItem("tuob") === "4g278vWUb1" || window.localStorage.getItem("tuob") === "2fBkimFcIw" ||
          // window.localStorage.getItem("tuob") === "non7XHLBoT" ){

            // console.log("window.localStorage.getItem(tuob)::: " +  window.localStorage.getItem("tuob"));

            this.userObjectId = window.localStorage.getItem("tuob");

            // console.log(" this.userObjectId = " +  this.userObjectId);

            if (window.localStorage.getItem("tuisrecipient") === "true" && this.bypassDashboard === false) {
  
              this.showRecipientDashboard = true;
              console.log("showing dashboard");

            } else {
              this.bypassDashboard === false;
              const userPhotoFilename = window.localStorage.getItem("userPhotoFilename") !== null ? window.localStorage.getItem("userPhotoFilename") : undefined; // send any current filename

              // /alert("getting userData userPhotoFilename is: " + userPhotoFilename);

              this.userData = await Parse.Cloud.run("getUserData", {
                tuob: window.localStorage.getItem("tuob"),
                userPhotoFilename: userPhotoFilename,
                });  

              if (this.userData.userPhotoFileType !== undefined) {
                window.localStorage.setItem("userPhotoFileType", this.userData.userPhotoFileType);
              } else {
                window.localStorage.setItem("userPhotoFileType", "jpeg"); // default just in case
              }

              // console.log("this.userData.userPhotoFilename: " + this.userData.userPhotoFilename);
              const userPhotoFileType = window.localStorage.getItem("userPhotoFileType");

              if (this.userData.userPhotoFilename !== undefined) {
                // has been updated
                window.localStorage.setItem("userPhotoFilename", this.userData.userPhotoFilename);
                window.localStorage.setItem("userPhotoFileData", 'data:image/' + userPhotoFileType + ';base64,' + this.userData.userPhotoFileData);

                // /alert("set localStorage ON ACCOUNT PREP userPhotoFilename: " +  window.localStorage.getItem("userPhotoFilename"));
                // /alert("set localStorage userPhotoFileData: " + window.localStorage.getItem("userPhotoFileData"));

              } else {
                // /alert ("did NOT set either userPhotoFilename or userPhotoFileData");
              }

              

              // console.log("USERDATA on accountPrep in ELSE::: " + JSON.stringify(this.userData, null, 2));

              this.showAccountScreen = true;

              if (window.localStorage.getItem("refreshstripeonboardinglink") !== null) {
                window.localStorage.removeItem("refreshstripeonboardinglink");
                this.doOnboarding();
              } 

              if (window.localStorage.getItem("mustDoKYC") !== null) {
                window.localStorage.removeItem("mustDoKYC");
                // console.log("ok we are doing onboarding now");
                this.doOnboarding();
              } 

              if (window.localStorage.getItem("returnfromstripeonboarding") !== null) {
                window.localStorage.removeItem("returnfromstripeonboarding");
                await Parse.Cloud.run("saveUserFromWebApp", {userObjectId: window.localStorage.getItem("tuob"), "isDoingStripeOnboarding" : "setUndefined"});
              } 



            }
          // } else {
          //   this.showPlaceholderAccountPage = true;
          // }
        }

        if (window.localStorage.getItem("tudis") !== null){
          this.displayname = window.localStorage.getItem("tudis");
        }

        if (window.localStorage.getItem("tuconnecttovenue") !== null){
          // console.log("tuconnecttovenueinprocess");
          window.localStorage.setItem("tuconnecttovenueinprocess", true);
          // console.log("tuconnecttovenueinprocess SET TRUE");
        }

       
        if (window.localStorage.getItem("masterregisteringqrcode") !== null) {
          // console.log("DETERMINING masterregisteringqrcode");
          this.showPlaceholderAccountPage = false;
          const newClientNumber = await Parse.Cloud.run("getNewClientNumber", undefined); 
          this.$refs['email-signup'].value = "newclient" + newClientNumber + "nctu@thanku.app";
          this.$refs['pw-signup'].value = "1111111";
          this.$refs['displayname-signup'].value = "New Client " + newClientNumber;
          this.showAccountScreen = false;
          this.showSignUp = true;
          // console.log("SHOULD BE SHOWING SIGN UP!!!!");
        } else {
          
          // console.log("NOT masterregisteringqrcode");
        }
        this.componentKey = !this.componentKey; 
        this.detectCountryForDropdown();

      } catch (e) {
        // console.log("ERROR in determineInitialView: " + e.message);
        this.popUpMsgTitle = "Sorry!";
        this.popUpMsgBody = "There was an error showing this screen, please contact tech@thanku.app with the following error message: " + e.message;
        this.showPopUpOk = true;
      }
    
    },
    async deployLogRocket(){
      
     

      var LRObjectId = new Date().getTime();

      if (window.localStorage.getItem("tuob") !== null) {
        LRObjectId = window.localStorage.getItem("tuob");
      }
      
      if (LRObjectId === "4g278vWUb1" || LRObjectId === "2fBkimFcIw" ){
        return; // don't record myself
      }

      var LRDisplayname = "LR Name not given";

      if (window.localStorage.getItem("tudis") !== null) {
        LRDisplayname = window.localStorage.getItem("tudis");
      }

      try {
        LogRocket.init('thanku-alt-3/thanku-alt-3');//LogRocket.init('thanku-alt-8/thanku-alt-8');//LogRocket.init('thanku-alt-2/thanku-alt-2');//LogRocket.init('fpi3vr/thanku');  //LogRocket.init("thanku-alt-7/thanku-alt-7"); //LogRocket.init('thanku-alt-6/thanku-alt-6'); //LogRocket.init('thanku-alt/tualt'); //LogRocket.init('thanku-alt-4/thanku-alt-4'); //LogRocket.init('thanku-alt-5/thanku-alt-5'); //
        LogRocket.identify(LRObjectId, {
          name: LRDisplayname + ": account prep",
          email: undefined,
          // Add your own custom user variables here, ie:

        });
      } catch (e) {
        // console.log("error starting LogRocket tipper id: " + this.tipper.objectId);
        
        const params = {
          toEmail: "appalert@thanku.app",
          subject: "ALERT! LogRocket initialisation error: " + e.message,
          body: "ALERT! LogRocket initialisation error: " + e.message,
        };

        Parse.Cloud.run("sendEmail", params);
        // fail silently do nothing
      }
    },
  },
  async created () {
    // console.log("PRE Stripe" + new Date().toISOString());
    Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
    // stripe = await Stripe(this.stripePublishableKey); this was the old way of doing it where we used <script on index.html
    stripe = await loadStripe(this.stripePublishableKey); // now we just bring in when needed
    // console.log("POST Stripe" + new Date().toISOString());

    this.deployLogRocket();

    if (window.localStorage.getItem("tuconnecttovenuename") !== null){
      this.atVenueName = " at " + window.localStorage.getItem("tuconnecttovenuename");
      if (this.atVenueName.length > 25){
        this.titleHeight = 10;
      }
    }

    this.tuid = window.localStorage.getItem("tuconnecttovenue") !== null ? window.localStorage.getItem("tuconnecttovenue") : undefined;


    console.log("created finished at accountPrep");

    // alert("Account PREP " + window.localStorage.getItem("willConnectRealUserDetails"));

    // console.log("this.bypassDashboard:: " + this.bypassDashboard);
  },
  async mounted() {
    // waited for the DOM

    // if (window.localStorage.getItem("tuob") !== null) {
      // if (window.localStorage.getItem("tuob") === "4g278vWUb1"){
            // this.showOrderButton = true;
      // }
    // }

    
    // this.doLogout();

    console.log("PREP is mounting");

    this.showAccountScreen = false;

    this.determineInitialView();

      this.showAccountScreen = false;

    console.log("DETERMINED!");

    // this.orderMode = "dev"; // not yet doing anything on wix

    // /console.log("I'm an account screen");
    // /shared.saveToUserPath(this.devEnv, "Account loaded " + new Date().toISOString(), 2000);


  }
});

</script>