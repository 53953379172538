<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
   <!-- showPopUpTwoOptions --><transition name="fade">
    <div  v-show="showPopUpTwoOptions" class="modal-overlayfrombottomblur" style="z-index: 997;">
      <div class="modal" style="padding-top: 2vh; padding-bottom: 2vh; width: 90%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=85%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.1 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">                 
              {{ this.PopUpTwoOptionsTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" style="padding-top: 1vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">                 
              {{ this.PopUpTwoOptionsMessage }}<span v-if="this.PopUpTwoOptionsMessage !==''"><br><br><b>{{this.PopUpTwoOptionsMessage2}}</b>{{this.PopUpTwoOptionsMessage3}}</span>
              </div>
            </td>
          </tr> 
          <tr v-show="this.showButton1===true">
            <td>
              <div style="padding-top: 2vh; padding-bottom: 2vh;"><button class="widebtn" style="width: 100%; height: 6vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption1();">{{ this.option1ButtonText }}</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-bottom: 2vh;"><button class="widebtn" style="width: 100%; height: 6vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption2();">{{ this.option2ButtonText }}</button></div>
            </td>
          </tr>
          <tr> <!-- spacer --> <td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
    <div v-show="this.showAccountScreen===true" id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 
            <table cellspacing="0" cellpadding="0" width=100% border=0 style="margin: auto; padding-left: 0vh; padding-right: 0vh; ">
                <tr>
                    <td>
                        <table width="100%" border=0 style="height: 4.5vh">
                            <tr>
                                <td width="50%">
                                    <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><span v-if="this.userData.isRecipient===false">Home</span><span v-else><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</span></button></div>
                                </td>
                                <td width="50%">
                                    <div class=divr>
                                    
                                        <button @click="this.doLogout()" class="text-only-button cancel divr"  :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Log out</button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            
                <tr><td height=1vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>

                <div class="userinfoframenowidth" style="height: 15vh; cursor: pointer;">
                    <table style="height:85%;" border=0 width=100%>
                        <thead>
                            <tr @click="this.doRecipientAccountEdit();" style="font-size: 0">
                                <td align=left width=25% style="height: 8vh; font-size: 0; text-align: center"><img :src="this.userImageDataURL" style="height: 12vh; border-radius:50%"></td>
                                <td >
                                    <!-- nested table -->
                                    <table width=100%>
                                    <tr>
                                        <td class="username"> {{ this.userData.displayname }}</td>
                                    </tr>
                                    <tr>
                                        <td class="businessname"><span v-if="this.user.emailDisplay">{{ this.user.emailDisplay }}</span></td> 
                                        
                                    </tr>
                                    <tr v-if="this.user.connectedTo!==undefined">
                                        <td class="lt-blue divl" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.8 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.user.connectedTo }}</td> 
                                        
                                    </tr>
                                    </table>
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="edit" src="../assets/chevronright.png">
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
               
                <tr v-if="this.showRecipientAccountScreen === true"><td height=1.5vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
                <tr v-if="this.showRecipientAccountScreen === true">
                    <td height=1.5vh >
                        <table width="100%" border=0 style="height: 1.5vh">
                            <tr>
                                <td width="100%">
                                    <div class="divl" style="color: #718096; font-weight: 600;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">PAYOUTS</div>
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                </tr>
                <tr v-if="this.showRecipientAccountScreen === true && this.userData.receiveLast4!==undefined" @click="doShowPayouts();">
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% >
                            
                            <tr>

                                <td align=center width=15% class="lt-blue divc">
                                    <img style="height: 3vh; vertical-align:middle" alt="back" src="../assets/payoutsicon.png">
                                </td>
                                
                                <td align=left width=75% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    Payouts
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="show payouts screen" src="../assets/chevronright.png">
                                </td>
                                
                            </tr>
                        </table>      
                    </td>
                </tr>
                <tr v-if="this.showRecipientAccountScreen === true"><td height=1.5vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>   
                <tr v-if="this.showRecipientAccountScreen === true" @click="this.doConnectBankAccount();">
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% >
                            
                            <tr>

                                <td align=center width=15% class="lt-blue divc">
                                    <img style="height: 3vh; vertical-align:middle" alt="back" src="../assets/editicon.png">
                                </td>
                                
                                <td align=left width=75% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    <span v-if="this.userData.receiveLast4===undefined">Connect bank account</span><span v-else>Paying to {{ this.userData.accountNameAbbr }}  {{ this.userData.receiveLast4 }}</span>
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="connect bank account" src="../assets/chevronright.png">
                                </td>
                                
                            </tr>
                        </table>      
                    </td>
                </tr>
                <tr v-if="this.showRecipientAccountScreen === true"><td height=1.5vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr> 

                <tr v-if="this.showRecipientAccountScreen === true">
                    <td height=1.5vh >
                        <table width="100%" border=0 style="height: 1.5vh">
                            <tr>
                                <td width="100%">
                                    <div class="divl" style="color: #718096; font-weight: 600;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">MANAGE</div>
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                </tr>
                <tr v-if="this.showRecipientAccountScreen === true && this.isPoolMaster === true">
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% @click="doShowManageConnectedUsers();">
                            
                            <tr>

                                <td align=center width=15% class="lt-blue divc">
                                    <img style="height: 3vh; vertical-align:middle" alt="back" src="../assets/manageconnectedusersicon.png">
                                </td>
                                
                                <td align=left width=75% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    Manage connected users
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="manage connected users" src="../assets/chevronright.png">
                                </td>
                                
                            </tr>
                        </table>      
                    </td>
                </tr>
                <tr v-if="this.showRecipientAccountScreen === true && this.isPoolMaster === true"><td height=1.5vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr> 
                <tr v-if="this.showRecipientAccountScreen === false">
                    <td height=1.5vh >
                        <table width="100%" border=0 style="height: 1.5vh">
                            <tr>
                                <td width="100%">
                                    <div class="divl" style="color: #718096; font-weight: 600;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">ACCOUNT</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                 <tr v-if="this.showRecipientAccountScreen === false" @click="this.doTipWithoutScanning()">
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% >
                            
                            <tr>

                                <td align=center width=15% class="lt-blue divc">
                                    <img style="height: 3vh; vertical-align:middle" alt="back" src="../assets/payoutsicongreen.png">
                                </td>
                                
                                <td align=left width=75% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    Tip without scanning
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="order thankU badges" src="../assets/chevronright.png">
                                </td>
                            </tr>
                            <tr v-if="this.user.mostRecentSalonVisited !== ''">
                                <td colspan=3 width="100%" style="color: #718096; font-size: 14px;" >
                                    at: {{this.user.mostRecentSalonVisited}} &nbsp;&nbsp;<button class="widebtn" style="width: 30%; font-size: 14px" @click="this.handleChangeButtonEvent($event)">Change</button>         
                                </td>
                            </tr>
                        </table>      
                    </td>
                </tr>
                <tr v-if="this.isPoolMaster === false"><td height=1.5vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr> 
                <tr v-if="this.isPoolMaster === false" @click="this.doShowTipHistory()">
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% >
                            
                            <tr>

                                <td align=center width=15% class="lt-blue divc">
                                    <img style="height: 3vh; vertical-align:middle" alt="back" src="../assets/orderfreeicon.png">
                                </td>
                                
                                <td align=left width=75% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    Transaction history
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="order thankU badges" src="../assets/chevronright.png">
                                </td>
                            </tr>
                        </table>      
                    </td>
                </tr>
                <tr v-if="this.isPoolMaster === false"><td height=1.5vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr> 
                <tr v-if="this.showRecipientAccountScreen === true" @click="this.orderThankUBadges();">
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% >
                            
                            <tr>

                                <td align=center width=15% class="lt-blue divc">
                                    <img style="height: 3vh; vertical-align:middle" alt="back" src="../assets/orderfreeicon.png">
                                </td>
                                
                                <td align=left width=75% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    Order free thankU badges
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="order thankU badges" src="../assets/chevronright.png">
                                </td>
                            </tr>
                        </table>      
                    </td>
                </tr>
                <tr v-if="this.showRecipientAccountScreen === true"><td height=1.5vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr> 
                <tr v-if="this.showRecipientAccountScreen === false" >
                    <!-- cant see a need for this until we get back into pools at which point we may do it differently anyway -->
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% @click="doShowPaymentMethods();">
                            
                            <tr>

                                <td align=center width=15% class="lt-blue divc">
                                    <img style="height: 3vh; vertical-align:middle" alt="back" src="../assets/managebadgesicon.png">
                                </td>
                                
                                <td align=left width=75% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    Payment methods
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="manage thankU badges" src="../assets/chevronright.png">
                                </td>
                                
                            </tr>
                        </table>      
                    </td>
                </tr>
                
                <tr v-if="this.showRecipientAccountScreen === false"><td height=1.5vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr> 
                <tr>
                    <td height=1.5vh >
                        <table width="100%" border=0 style="height: 1.5vh">
                            <tr>
                                <td width="100%">
                                    <div class="divl" style="color: #718096; font-weight: 600;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.7 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">THANKU</div>
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                </tr>
                <tr v-if="this.showRecipientAccountScreen === true" >
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% @click="this.doShowTippingOptions()">
                            
                            <tr>

                                <td align=center width=15% class="lt-blue divc">
                                    <img style="height: 3vh; vertical-align:middle" alt="back" src="../assets/recipientgivetipicon.png">
                                </td>
                                
                                <td align=left width=75% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }"><span v-if="this.hasGivenTipsBefore===true">View your tip giving account</span><span v-else>Tip another user</span>
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="tip another user" src="../assets/chevronright.png">
                                </td>
                                
                            </tr>
                        </table>      
                    </td>
                </tr>
                <tr v-if="this.showRecipientAccountScreen === false" >
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% @click="this.doShowRecipientOptions()">
                            
                            <tr>

                                <td align=center width=15% class="lt-blue divc">
                                    <img style="height: 3vh; vertical-align:middle" alt="back" src="../assets/recipientgivetipicon.png">
                                </td>
                                
                                <td align=left width=75% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }"><span v-if="this.hasReceivedTipsBefore===true">View your tip receiving account</span><span v-else>How to receive tips</span>
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="tip another user" src="../assets/chevronright.png">
                                </td>
                                
                            </tr>
                        </table>      
                    </td>
                </tr>
                <tr><td height=1.5vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr> 
                <tr>
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% @click="getHelp()">
                            
                            <tr>

                                <td align=center width=15% class="lt-blue divc">
                                    <img style="height: 3vh; vertical-align:middle" alt="back" src="../assets/helpicon.png">
                                </td>
                                
                                <td align=left width=75% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    Help
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="help" src="../assets/chevronright.png">
                                </td>
                                
                            </tr>
                        </table>      
                    </td>
                </tr>
                <tr><td height=1.5vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr> 
                <tr v-if="this.isSuperuser===true">
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% @click="doManagementDashboard()">
                            
                            <tr>

                                <td align=center width=15% class="lt-blue divc">
                                    <img style="height: 3vh; vertical-align:middle" alt="back" src="../assets/helpicon.png">
                                </td>
                                
                                <td align=left width=75% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px; color:1A202C" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                                    Management Dashboard
                                </td>
                                <td align=right width=10% >
                                    <img class="tulogo" style="height: 1.8vh;" alt="help" src="../assets/chevronright.png">
                                </td>
                                
                            </tr>
                        </table>      
                    </td>
                </tr>
                <tr v-if="this.isSuperuser===true">
                    <td>
                        <table align=left style="height:6.5vh; background-color: white; border-radius: 10px; cursor: pointer" border=0 width=100% @click="doSubmitRyftPayment()">
                            
                            <tr>
                                <td align=center width=15% class="lt-blue divc">
                                    <!-- <form id="ryft-pay-form"> -->
                                    <!-- form will be embedded here -->
                                    <button id="ryft-pay-btn">PAY GBP 1</button>
                                    <div id="ryft-pay-error"></div>
                                    <!-- </form> -->
                                </td>
                            </tr>
                        </table>      
                    </td>
                </tr>


                <tr><td height=1.5vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr> 
            </table> 
        </div>
    </div>
    <AccountEditScreen :key="componentKey" v-if="this.showAccountScreenEdit===true" :userData='this.userData' @next-action="doNext"/>
    <AccountPaymentMethodScreen v-if="this.showAccountPaymentMethodScreen===true" :userData='this.userData' @next-action="doNext"/>
    
    
</template>

<script>

const Parse = require('parse/node');

import shared from '../shared.js';
import {loadStripe} from '@stripe/stripe-js/pure';

import AccountEditScreen from './AccountEdit.vue';
import AccountPaymentMethodScreen from './AccountPaymentMethod.vue';

export default {
    name: 'RecipientDashboardScreen',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    props:  {
        userData: shared.userDataStruct(),
    },
    components: {
        AccountEditScreen,
        AccountPaymentMethodScreen,
    },
    data () {
        return {
            fontSizeNormal: 3.0, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.6,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 3.0, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 3.0,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            user: {},
            userImageDataURL: "",
            showPopUpTwoOptions: false,
            PopUpTwoOptionsTitle: "",
            PopUpTwoOptionsMessage: "",  
            PopUpTwoOptionsMessage2: "",
            PopUpTwoOptionsMessage3: "",
            option1ButtonText: "",
            option2ButtonText: "",
            showAccountScreenEdit: false,
            showAccountPaymentMethodScreen: false,
            showAccountScreen: true,
            showButton1: true,
            componentKey: 0, // this is to be able to rerender the component so that the Account Photo Edit reloads
            vhMult: 0,
            showRecipientAccountScreen: false,
            hasGivenTipsBefore: false,
            hasReceivedTipsBefore: false,
            isPoolMaster: false,
            isSuperuser: false,
            stripePublishableKey: process.env.VUE_APP_STRIPE_ENV === "dev" ? process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_TEST : process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_LIVE,
        }
    },
    methods: {
        
        handleChangeButtonEvent(event){
            event.stopPropagation();
            // console.log("do handleChangeButtonEvent");
            this.userData.nextScreen = "tipwithoutscanning";
            this.$emit("next-action", this.userData);
        },
        doTipWithoutScanning(){

            // console.log("doTipWithoutScanning")

            if (this.user.mostRecentSalonVisitedTUID === ""){
                // console.log("do Tip without scanning");
                this.userData.nextScreen = "tipwithoutscanning";
                this.$emit("next-action", this.userData);
            } else {
                window.localStorage.setItem("tutws", true);
                this.userData.nextScreen = "processTUID";
                // console.log("this.user.mostRecentSalonVisitedTUID: " + this.user.mostRecentSalonVisitedTUID);
                this.userData.mostRecentSalonVisitedTUID = this.user.mostRecentSalonVisitedTUID;
                this.$emit("next-action", this.userData);
            }
        },
        forceRerender() {
            this.componentKey += 1;
        },
        doFontSizeComputed () {
        
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        
        },
        async doNext(userData) {

            let nextAction = userData.nextScreen;

            // console.log("nextAction ON ACCOUNT SCREEN::: " + nextAction + "   user: " + JSON.stringify(userData, null, 2));

            if (nextAction === "logOut") {
                await this.doLogout();
                nextAction = "showLoggedOut"; // just let it flow through to home page
            }

            

            if (nextAction === "ReloadAccountPhotoScreen") {
                // console.log("do ReloadAccountPhotoScreen screen");
                this.user.email = userData.email;

                this.user.emailDisplay = this.user.email;

                if (this.user.emailDisplay.length > 25) {
                    this.user.emailDisplay = this.user.emailDisplay.substring(0, 22) + "...";
                }

                this.user.userId = userData.userObjectId;

                if (userData.userThumbnail) {
                    this.userImageDataURL = userData.userThumbnail; // for display in this component
                    this.userData.userThumbnail = userData.userThumbnail; // for passing to next component
                    this.userData.goStraightToPhotoEdit = true;
                    // console.log("NEW USERDATA: " + JSON.stringify(this.userData));
                } else {
                    this.userImageDataURL = shared.getAccountPersonDataURL();
                    // console.log("using accountperson");
                }

                this.forceRerender();

            } else if (nextAction === "showAccountScreen") {
                // console.log("do account screen");
                this.user.email = userData.email;

                this.user.emailDisplay = this.user.email;

                if (this.user.emailDisplay.length > 25) {
                    this.user.emailDisplay = this.user.emailDisplay.substring(0, 22) + "...";
                }
        
                this.user.userId = userData.userObjectId;

                if (userData.userThumbnail) {
                    this.userImageDataURL = userData.userThumbnail;
                    this.userData.userThumbnail = userData.userThumbnail; // for passing to next component
                }

                this.showAccountScreenEdit = false;
                this.showAccountPaymentMethodScreen = false;
                this.showAccountScreen = true;


                
            } else if (nextAction === "showAccountScreenFromPaymentMethodScreen") {
                // console.log("do account screen from payment method screen");
                this.userData.paymentMethod = userData.paymentMethod;

                this.showAccountScreenEdit = false;
                this.showAccountPaymentMethodScreen = false;
                this.showAccountScreen = true;
                
            } else {
                // console.log("doing next:: " + nextAction);
                let returnParentJSON = {
                    showHomeScreen: true,
                    redirect: nextAction
                };
                this.$emit('return-parent-json', returnParentJSON);
            }
        },
        async getUserThumbnail(userObjectId) {

            // console.log("objectID::: " + userObjectId);
    
            var params =  {};
            params["userObjectsArray"] = [{objectId: userObjectId}];
            params["mainOrThumb"] = "userPhoto";

            await Parse.Cloud.run("getUserPhotoThumbs", params).then( TUImageData => {
                if (TUImageData === undefined) {
                    
                    // do nothing, there's a problem getting the image, probably because it's saved in a replica DB
                } else {
                    // console.log("TUImageData::" + TUImageData + "::");
                    var hasFoundImage = false;
                    var i;
                    for (i = 0; i < TUImageData.length; i++) {
                        if (TUImageData[i][0] === userObjectId){
                            // console.log("doing 1");
                            this.userImageDataURL = 'data:image/' + TUImageData[i][2] + ';base64,' + TUImageData[i][1];
                            this.userData.userThumbnail = this.userImageDataURL;
                            // console.log("image2: " + this.userImageDataURL);
                            hasFoundImage = true;
                        } 
                    }
                    if (hasFoundImage === false) {
                        this.userImageDataURL = require('@/assets/accountperson.png');
                        this.userData.userThumbnail = this.userImageDataURL;
                    }
                }
            }).catch(function(error){
                // /console.log("BROWSER getUserPhotoThumbs FAILED");
                console.error(error);
                throw error;
            });
        },
        doShowTipHistory(){
            this.userData.fromScreen = "recipientAccountScreen";
            this.userData.nextScreen = "showTipHistory";
            if(this.showRecipientAccountScreen === true) {
                this.userData.tipHistoryMode = "recipient";
            } else {
                this.userData.tipHistoryMode = "tipper";
            }
            this.$emit("next-action", this.userData);
        },
        doRecipientAccountEdit(){
            this.showAccountScreenEdit = true;
            this.showAccountPaymentMethodScreen = false;
            this.showAccountScreen = false;
        },
        goBack(){
            if (this.showRecipientAccountScreen === true) {
                this.userData.nextScreen = "showRecipientDashboardScreen";
            } else {
                this.userData.nextScreen = "";
            }
            this.$emit("next-action", this.userData); 
        },
        doConnectBankAccount(){
            // console.log("doing connect bank account");
            this.showAccountScreen = false;
            this.userData.nextScreen = "connectBankAccount";
            this.$emit("next-action", this.userData);

        },
        doShowConnectBankAccountOptions(){
            this.PopUpTwoOptionsTitle = "Connect bank account";
            
            this.PopUpTwoOptionsMessage = "To receive tips with thankU, you now need to connect your thankU account to your bank account";
            this.PopUpTwoOptionsMessage2 = "";
            this.PopUpTwoOptionsMessage3 = "";
            this.option1ButtonText = "Connect";
            this.option2ButtonText = "Later";
            this.showHomeScreen = false;
            this.showPopUpTwoOptions = true;
        },
        doShowPayouts(){
            // console.log("now I am on RecipientAccount and I should be: " + this.user.userId);
            this.userData.fromScreen = "showAccountScreen";
            this.userData.nextScreen = "showPayouts";
            // console.log("this.userData::: " + JSON.stringify(this.userData, null, 2));
            // Parse.Cloud.run("sendEmail", {
            //     toEmail: "appnotif@thanku.app", 
            //     subject: "ALERT! " + this.user.displayname + " viewed Payouts",
            //     body: "",
            // });
            this.$emit("next-action", this.userData);
        },
        doLogout(){
            this.userData.nextScreen = "logOut";
            this.$emit("next-action", this.userData);
        },
        orderThankUBadges(){
            window.localStorage.setItem("nextScreen", "showAccountScreen");
            this.userData.fromScreen = "showAccountScreen";
            // this.userData.nextScreen = "gettipsstart";
            this.userData.nextScreen = "gettipsstartnew";
            this.$emit("next-action", this.userData);

        },

        doShowManageThankUBadges(){
            this.PopUpTwoOptionsTitle = "Manage thankU badges";
            
            this.PopUpTwoOptionsMessage = "I can't think of why anyone would need this in a salon context so leaving it for now";
            this.PopUpTwoOptionsMessage2 = "";
            this.PopUpTwoOptionsMessage3 = "";
            this.option1ButtonText = "Update your thankU badges";
            this.option2ButtonText = "Ok";
            this.showHomeScreen = false;
            this.showPopUpTwoOptions = true;
        },

        doShowPaymentMethods(){
            
            this.showHomeScreen = false;
            this.showAccountScreenEdit = false;
            this.showAccountPaymentMethodScreen = true;
        },

        doShowManageConnectedUsers(){
            this.PopUpTwoOptionsTitle = "Manage connected users";
            
            this.PopUpTwoOptionsMessage = "Your staff set themselves up to receive tips with thankU by scanning your business's thankU QR badge and tapping 'get tips here...'. It typically takes them 1 minute if they have their bank details to hand. When staff leave, just email us - you can use the 'Update your thankU list' button below, and we will remove them for you. We used to have an 'in app' way of doing this, but no one used it and everyone preferred to just email us!";
            this.PopUpTwoOptionsMessage2 = "";
            this.PopUpTwoOptionsMessage3 = "";
            this.option1ButtonText = "Update your thankU list";
            this.option2ButtonText = "Ok";
            this.showHomeScreen = false;
            this.showPopUpTwoOptions = true;
        },
        doShowTippingOptions(){

            if (this.hasGivenTipsBefore === true){
                this.showRecipientAccountScreen = false;
            } else {
                this.PopUpTwoOptionsTitle = "Tip another user";
                
                this.PopUpTwoOptionsMessage = "To tip with thankU, if you are at the location where the person you want to tip is, simply scan the thankU QR badge just the same as any other thankU user, and if you aren't, you can use the Tip Without Scanning button below - choose the business where they work from the list, and then tip as normal.";
                this.PopUpTwoOptionsMessage2 = "";
                this.PopUpTwoOptionsMessage3 = "";
                this.option1ButtonText = "Tip without scanning";
                this.option2ButtonText = "Ok";
                this.showHomeScreen = false;
                this.showPopUpTwoOptions = true;

            }
            
        },
        doShowRecipientOptions(){

            if (this.hasReceivedTipsBefore === true){
                this.showRecipientAccountScreen = true;
            } else {
                this.PopUpTwoOptionsTitle = "How to receive tips";
                
                this.PopUpTwoOptionsMessage = "To get tips with thankU, you need to request free thankU QR badge media from us and/or we can set you up with your own thankU link you can give your clients which will take them straight to your thankU tipping page. To request either or both, just tap the 'Order free thankU badges' button below";
                this.PopUpTwoOptionsMessage2 = "NOTE: ";
                this.PopUpTwoOptionsMessage3 = "If there is already thankU QR media where you work and you want to receive tips there, simply scan the QR media just like a client, tap the 'get tips here' button and follow instructions";
                this.option1ButtonText = "Order free thankU badges";
                this.option2ButtonText = "Ok";
                this.showHomeScreen = false;
                this.showPopUpTwoOptions = true;

            }
            
        },
        doOption1(){
            this.showPopUpTwoOptions = false;
            
            if (this.option1ButtonText === "Tip without scanning"){
                // console.log("do Tip without scanning");
                this.userData.nextScreen = "tipwithoutscanning";
                
                this.$emit("next-action", this.userData);
            } else if (this.option1ButtonText === "Order free thankU badges"){
                this.orderThankUBadges();

            } else if (this.option1ButtonText === "Update your thankU list"){
                const url = "mailto:updatethankulist@thanku.app?subject=request to update thankU list&body=Just let us know which staff member or members you would like us to remove (or change) and we will get it done without delay... Just advise us their name as it appears in your list, or their email address..."

                window.open(url);
            } else if (this.PopUpTwoOptionsTitle === "Connect bank account"){
                
                window.localStorage.setItem("tuconnecttovenue", "bankAccountOnly");
                // console.log("DID SET tuconnecttovenue");
                this.doConnectBankAccount();
            }
            this.resetPopUpTwoOptions();
        },
        doOption2(){
            this.showPopUpTwoOptions = false;
            this.resetPopUpTwoOptions();
            
        },
        resetPopUpTwoOptions(){

            this.PopUpTwoOptionsTitle = "";
            this.PopUpTwoOptionsMessage = "";
            this.PopUpTwoOptionsMessage2 = "";
            this.PopUpTwoOptionsMessage3 = "";
            this.option1ButtonText = "";
            this.option2ButtonText = "";
            
        },
        getHelp(){
            window.localStorage.setItem("nextScreen", "showAccountScreen");
            
            if (this.showRecipientAccountScreen === true){
                window.localStorage.setItem("accountScreenStatus", "recipient");
                this.doShowGetTipsMoreInfo();
            } else {
                window.localStorage.setItem("accountScreenStatus", "tipper");
                this.doShowGiveTipsMoreInfo();
            }
        },
        doShowGetTipsMoreInfo(){
            // console.log("Yup");
            this.userData.nextScreen = "gettipsmoreinfo";
            this.$emit("next-action", this.userData);
            // this.$router.push('gettipsmoreinfo'); 
        },
        doShowGiveTipsMoreInfo(){
            // console.log("Yup");
            this.userData.nextScreen = "givetipsmoreinfo";
            this.$emit("next-action", this.userData);
            // this.$router.push('gettipsmoreinfo'); 
        },
        setUserTippingStatusVars(){


            if (this.userData.isRecipient === true){
                if (window.localStorage.getItem("accountScreenStatus") !== null && window.localStorage.getItem("accountScreenStatus") === "tipper") {
                    // console.log("IIISSSSSSSSSSSS recipient");
                    window.localStorage.removeItem("accountScreenStatus");
                    this.showRecipientAccountScreen = false;
                } else {
                    // console.log("IIISSSSSSSSSSSS tipper");
                    window.localStorage.removeItem("accountScreenStatus");
                    this.showRecipientAccountScreen = true;
                }
            } 
            if (this.userData.hasGivenTipsBefore === true){
                this.hasGivenTipsBefore = true;
            } 
            if (this.userData.hasReceivedTipsBefore === true){
                this.hasReceivedTipsBefore = true;
            }
            if (this.userData.isPoolMaster === true){
                this.isPoolMaster = true;
            }
        },
        doManagementDashboard(){
            this.userData.fromScreen = "showAccountScreen";
            this.userData.nextScreen = "showManagementDashboard";
            // console.log("this.userData::: " + JSON.stringify(this.userData, null, 2));
            this.$emit("next-action", this.userData);
        },
        setDelayedTitle(){
            document.title = "thankU"; // this is so if you save it to your home screen the proposed title is just 'thankU'
        },
        async doStripeResilienceTest(){

            let errorType = "";
            let repeatInterval = 3000;

            try {

                let preTimeInMillis;
                let postTimeInMillis;

                preTimeInMillis = new Date().getTime();

                console.log("about to load Stripe in stripeResilienceTesting");  
                errorType = "loadStripe";
                let stripe = await loadStripe(this.stripePublishableKey); 

                
                postTimeInMillis = new Date().getTime();
                let secondsTaken = (postTimeInMillis - preTimeInMillis)/1000;
                console.log("JUST completed await load Stripe in stripeResilienceTesting");
                if (secondsTaken > 0.6) {
                    Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "SLOW stripeResilienceTesting took " + secondsTaken.toFixed(1) + " seconds", body: ""}); 
                } else {
                    // Parse.Cloud.run("sendEmail", { toEmail: "appnotif@thanku.app", subject: "Normal stripeResilienceTesting took " + secondsTaken.toFixed(1) + " seconds", body: ""}); 
                }
                errorType = "canMakePayment";
                preTimeInMillis = new Date().getTime();
                let paymentRequestJSON = this.makePaymentRequestJSON("1", "TEST");
                // console.log("paymentRequestJSON: " + JSON.stringify(paymentRequestJSON, null, 2));
                let paymentRequest = stripe.paymentRequest(paymentRequestJSON);
                // console.log("paymentRequest: " + JSON.stringify(paymentRequest, null, 2));
                let result = await paymentRequest.canMakePayment();
                postTimeInMillis = new Date().getTime();
                secondsTaken = (postTimeInMillis - preTimeInMillis)/1000;
                console.log("JUST completed await canMakePayment in stripeResilienceTesting");
                if (secondsTaken > 0.6) {
                    Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "SLOW CANMAKEPAYMENT in stripeResilienceTesting took " + secondsTaken.toFixed(1) + " seconds", body: ""}); 
                } else {
                    // Parse.Cloud.run("sendEmail", { toEmail: "appnotif@thanku.app", subject: "Normal stripeResilienceTesting took " + secondsTaken.toFixed(1) + " seconds", body: ""}); 
                }
                window.setTimeout(this.doStripeResilienceTest, repeatInterval); 
                
                
            } catch (e) {
                console.log("ERROR in stripeResilienceTesting: " + e.message);
                if (errorType === "loadStripe"){
                    Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "ERROR LOADSTRIPE in stripeResilienceTesting ", body: "Error is: " + e.message}); 
                    window.setTimeout(this.doStripeResilienceTest, repeatInterval); 
                } else if (errorType === "canMakePayment"){
                    Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "ERROR CANMAKEPAYMENT in stripeResilienceTesting ", body: "Error is: " + e.message}); 
                    window.setTimeout(this.doStripeResilienceTest, repeatInterval); 
                } else {
                    Parse.Cloud.run("sendEmail", { toEmail: "appalert@thanku.app", subject: "ERROR OTHER in stripeResilienceTesting ", body: "Error is: " + e.message}); 
                }

            }


        },
        makePaymentRequestJSON(buttonAmount, origin){
            
            return {
                country: "GB", 
                currency: "gbp",
                total: {
                    label: "test",
                    amount: +(100).toFixed(0),
                },
            }
        },
        doLoadRyft(){

            // document.head.innerHTML += '<script src="https://embedded.ryftpay.com/v1/ryft.min.js"<\/script>';

            window.setTimeout(this.initRyft, 2000);


        },
        initRyft(){
            Ryft.init({
                publicKey: "pk_sandbox_mYEJMb3kDwEaxfRSMnOLyDvnlBWLAy8AQnz7CTmd2XLKzev+D9IN6VjF8EX6jXfJ",
                // clientSecret: "{{client secret}}", // optional here - this can be supplied on Ryft.attemptPayment()
                accountId: "ac_b108bd2c-69fe-4525-996f-c716961bd83e",
            });
        },
        doSubmitRyftPayment(){

        },
    },

    async created(){
        console.log("GET ME aCCOUNT SCREEN created");
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
        
        // console.log("userData on CREATING Account.vue: " + JSON.stringify(this.userData, null, 2));

        this.user.displayname = this.userData.displayname; //window.localStorage.getItem("tudis");
        this.user.email = this.userData.email;

        if (this.userData.mostRecentSalonVisited !== undefined){
            this.user.mostRecentSalonVisited = this.userData.mostRecentSalonVisited;
            this.user.mostRecentSalonVisitedTUID = this.userData.mostRecentSalonVisitedTUID;
        } else {
            this.user.mostRecentSalonVisited = "";
            this.user.mostRecentSalonVisitedTUID = "";
        }

        this.user.emailDisplay = this.user.email;

        if (this.user.emailDisplay.length > 25) {
            this.user.emailDisplay = this.user.emailDisplay.substring(0, 22) + "...";
        }

        this.user.userId = this.userData.userObjectId;
        if (this.userData.isSuperuser !== undefined){
            this.isSuperuser = this.userData.isSuperuser;
        }

        this.setUserTippingStatusVars();
    
        if (this.userData.venuesConnectedAtArray.length > 0){
            this.user.connectedTo = "getting tips at ";
            var connectorStr = "";
            for (const venue of this.userData.venuesConnectedAtArray) {
                this.user.connectedTo += connectorStr + venue.venueLocationShort ;
                connectorStr = ", ";
            }
        }

        // console.log("Account Screen USER: " + JSON.stringify(this.userData));
        
        if (this.userData.userPhotoFilename !== undefined) {
            window.localStorage.setItem("userPhotoFilename", this.userData.userPhotoFilename);
            // /alert("SET localstorage userPhotoFilename to: " + window.localStorage.getItem("userPhotoFilename"));
        }

        if (window.localStorage.getItem("userPhotoFileData") !== null) {

            const userPhotoFileType = window.localStorage.getItem("userPhotoFileType") !== null ? window.localStorage.getItem("userPhotoFileType") : 'jpeg';

            this.userImageDataURL = window.localStorage.getItem("userPhotoFileData");
            // console.log("doing 2");
            // this.userImageDataURL = 'data:image/' + userPhotoFileType + ';base64,' + window.localStorage.getItem("userPhotoFileData");

            // console.log("this.userImageDataURL: " + this.userImageDataURL);
            this.userData.userThumbnail = this.userImageDataURL;

            // /// / /alert("ALREADY HAD localStorage userPhotoFiledata: " + this.userImageDataURL);
          
        } else {
            // console.log("ok let's get the thumbnail!");
            await this.getUserThumbnail(window.localStorage.getItem("tuob"));
            
            // /// / /alert("had to getUserThumbnail: " + this.userData.userThumbnail);
        }

        if (this.userData.receiveLast4 === undefined && this.userData.isRecipient === true && window.localStorage.getItem("tuhassetupbank") === null){
            if (window.localStorage.getItem("dontDoShowConnectBankAccountOptions") === null){
                console.log("We can show doShowConnectBankAccountOptions");
                // window.localStorage.removeItem("suppressOneBankNag");
                this.doShowConnectBankAccountOptions();
            } else {
                // window.localStorage.setItem("suppressOneBankNag", "yes");
                // window.localStorage.removeItem("dontDoShowConnectBankAccountOptions");
                console.log("here we are");
            }
        }


    },
    mounted(){
        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();
        shared.saveToUserPath(this.devEnv, "Viewed recipient account screen " + new Date().toISOString(), this.globalPushForwardInterval);   

        window.setTimeout(this.setDelayedTitle, 1500); 

        if (window.localStorage.getItem("willConnectRealUserDetails") !== null){
        
        //   alert("ON ACCOUNT willConnectRealUserDetails is still here");

        } else {
        //   alert("ON  ACCOUNT willConnectRealUserDetails WAS NOT THERE!!!!!");
        }

        if (window.localStorage.getItem("tuob") !== null && window.localStorage.getItem("tuob") === "4g278vWUb1") {
            console.log("start Stripe Resilience Test");
            this.doStripeResilienceTest();

            this.doLoadRyft();
        }

    }
}
</script>

<style scoped>

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 8vh;
  height: 4vh;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 3vh;
  width: 3vh;
  left: 4px;
  bottom: 0.5vh;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #68D391;
}

input:focus + .slider {
  box-shadow: 0 0 1px #68D391;
}

input:checked + .slider:before {
  -webkit-transform: translateX(3.8vh);
  -ms-transform: translateX(3.8vh);
  transform: translateX(3.8vh);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>